<template>
  <div class="container">
    <div class="company-container">
      <header>
        <h1>TECHSSO - Mua sắm trực tuyến dễ dàng, thuận tiện</h1>
      </header>

      <main>
        <section class="services">
          <h2>Các dịch vụ của TECHSSO</h2>
          <ul class="services-list">
            <li class="service">
              <img src="@/assets/images/quy-trinh-mua-hang.png" alt="Laptop" />
              <h3>Mua hàng trực tuyến</h3>
              <p>
                Tìm kiếm và đặt mua các sản phẩm trong cửa hàng, đây website bán
                hàng uy tín tại Việt Nam
              </p>
            </li>
            <li class="service">
              <img src="@/assets/images/muahohangnhat.png" alt="Điện thoại" />
              <h3>Dịch vụ mua sắm quốc tế</h3>
              <p>
                Nếu bạn muốn mua các sản phẩm từ các trang web mua sắm nước
                ngoài, TECHSSO sẽ giúp bạn thực hiện điều đó.
              </p>
            </li>
          </ul>
        </section>

        <section class="benefits">
          <h2>Lợi ích khi mua hàng tại TECHSSO</h2>
          <ul class="benefits-list">
            <li class="benefit">
              <img src="@/assets/images/check.png" alt="Kiểm tra" />
              <p>Sản phẩm chính hãng, chất lượng cao</p>
            </li>
            <li class="benefit">
              <img src="@/assets/images/price.png" alt="Giá cả" />
              <p>Giá cả cạnh tranh</p>
            </li>
            <li class="benefit">
              <img src="@/assets/images/shipping.png" alt="Vận chuyển" />
              <p>Dịch vụ vận chuyển nhanh chóng, an toàn</p>
            </li>
            <li class="benefit">
              <img src="@/assets/images/support.png" alt="Hỗ trợ" />
              <p>Hỗ trợ khách hàng tận tình, chu đáo</p>
            </li>
          </ul>
        </section>

        <section class="steps">
          <h2>Cách thức mua hàng tại TECHSSO</h2>
          <ol class="steps-list">
            <li>
              <p>Tìm kiếm sản phẩm bạn muốn mua trên website của TECHSSO.</p>
            </li>
            <li>
              <p>Thêm sản phẩm vào giỏ hàng và tiến hành thanh toán.</p>
            </li>
            <li>
              <p>
                Chúng tôi sẽ nhận đơn hàng của bạn và tiến hành mua hộ, vận
                chuyển hàng hóa về Việt Nam.
              </p>
            </li>
            <li>
              <p>
                Sau khi hàng hóa về Việt Nam, chúng tôi sẽ thông báo cho bạn và
                bạn có thể nhận hàng tại địa chỉ của mình.
              </p>
            </li>
          </ol>
        </section>

        <section class="contact">
          <h2>Liên hệ với TECHSSO</h2>
          <p>
            Nếu bạn cần được tư vấn thêm về các dịch vụ của TECHSSO <br />
            vui lòng liên hệ với chúng tôi qua hotline 0353 155 486.
          </p>
        </section>

        <section class="cta">
          <h2 style="color: #fff">
            Trải nghiệm mua sắm trực tuyến dễ dàng, thuận tiện cùng TECHSSO ngay
            hôm nay!
          </h2>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
/* Reset some default styles */
body,
h1,
h2,
h3,
p,
ul,
ol,
li {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif;
  line-height: 1.6;
}
.container {
  display: block;
}
.company-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

header {
  text-align: center;
  margin-bottom: 20px;
}

h1 {
  color: #333;
  font-size: 2em;
  margin-bottom: 10px;
}
h2 {
  color: #333;
  font-size: 18px;
  line-height: 40px;
}

.services-list {
  list-style: none;
  display: flex;

  flex-wrap: wrap;
  // grid-template-columns: 25% 25% 25% 25%;
}
.benefits-list {
  list-style: none;
  display: grid;

  // flex-wrap: wrap;
  grid-template-columns: 25% 25% 25% 25%;
}

.service,
.benefit {
  text-align: center;
  margin: 10px;
  padding: 20px 12px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.service:hover,
.benefit:hover {
  transform: scale(1.05);
}

.service img {
  max-width: 100%;
  height: auto;
}
.benefit img {
  max-width: 100%;
  height: 80px;
}

.steps-list {
  list-style: decimal;
  margin-left: 20px;
  line-height: 24px;
}

.contact,
.cta {
  margin-top: 20px;
  text-align: center;
}

.cta {
  background-color: #4caf50;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
}

.cta h2 {
  font-size: 1.5em;
}

/* Responsive design - adjust as needed */
@media screen and (max-width: 678px) {
  .services-list,
  .benefits-list {
    flex-direction: column;
    grid-template-columns: 50% 50%;
  }
  h1 {
    font-size: 20px;
  }
  h2 {
    font-size: 16px;
  }
  .cta {
    padding: 10px;
    border-radius: 8px;
  }
  .cta h2 {
    font-size: 1em;
  }
}
</style>
