<template>
  <div>
    <div class="container-web">
      <div class="cart-container">
        <div class="cart-imageandinfo">
          <!-- <el-checkbox class="cart-checkbox" v-model="checked"></el-checkbox> -->
          <div>
            <img class="cart-image" :src="dataProduct.LinkImage" alt="" />
          </div>
          <div class="pl-20" style="margin: auto 0px">
            <h4 class="font-size-p">{{ dataProduct.ProductName }}</h4>
            <!-- <h5 class="mt-5 font-size-p">{{ dataProduct.BodyOrgan }}</h5> -->
            <h5 class="mt-5 font-size-h6" style="font-weight: 400">
              Loại: {{ dataProduct.Tag }}
            </h5>
          </div>
        </div>
        <h4
          id="unitPrice"
          class="font-size-p"
          style="width: 10%; text-align: center"
        >
          <el-select v-model="dataProduct.Unit" placeholder="Đơn vị">
            <el-option
              v-for="(item, index) in dataProduct.UnitLst"
              :key="index"
              :label="item.Unit"
              :value="item.Unit"
            >
            </el-option>
          </el-select>
        </h4>
        <h4
          id="unitPrice"
          class="font-size-p"
          style="width: 10%; text-align: center"
        >
          {{ new Intl.NumberFormat().format(dataProduct.ExpriceShow) }} đ
        </h4>
        <div class="cart-quantity">
          <button class="bt-minus" @click="btMinus">
            <i class="fa-solid fa-minus"></i>
          </button>
          <div class="cart-quantity-line1"></div>
          <el-input
            class="cart-quantity-number font-size-h5"
            style="color: #040404; margin-top: -10px; text-align: center"
            v-model="dataProduct.QuantityProduct"
          ></el-input>
          <div class="cart-quantity-line2"></div>
          <button @click="btPlus"><i class="fa-solid fa-plus"></i></button>
        </div>
        <h4
          id="totalMoneyProduct"
          style="color: #c3262e; width: 10%; text-align: center"
          class="font-size-p"
        >
          {{ new Intl.NumberFormat().format(dataProduct.TotalMoney) }} đ
        </h4>
        <button class="cartCell-bt-delete" @click="btDelete">
          <i class="fa-solid fa-trash-can"></i>
        </button>
      </div>
    </div>

    <div class="container-mobile1">
      <div class="cart-container-mobile">
        <div class="image">
          <img class="cart-image" :src="dataProduct.LinkImage" alt="" />
        </div>
        <div class="content pl-20" style="margin: auto 0px">
          <div class="top-name">
            <div>
              <h4 class="font-size-p">{{ dataProduct.ProductName }}</h4>
              <h5 class="mt-5 font-size-h6" style="font-weight: 400">
                Loại: {{ dataProduct.Tag }}
              </h5>
            </div>
            <button class="cartCell-bt-delete" @click="btDelete">
              <i class="fa-solid fa-trash-can"></i>
            </button>
          </div>
          <div style="display: flex">
            <div class="cart-quantity-mobile">
              <button class="bt-minus" @click="btMinus">
                <i class="fa-solid fa-minus font-size-p"></i>
              </button>

              <el-input
                class="cart-quantity-number font-size-p"
                style="color: #040404; margin-top: -6px; text-align: center"
                v-model="dataProduct.QuantityProduct"
              ></el-input>
              <button @click="btPlus">
                <i class="fa-solid fa-plus font-size-p"></i>
              </button>
            </div>
            <h4
              id="unitPrice"
              class="font-size-p"
              style="width: 110px; text-align: center"
            >
              <el-select
                v-model="dataProduct.Unit"
                placeholder="Đơn vị"
                size="mini"
              >
                <el-option
                  v-for="(item, index) in dataProduct.UnitLst"
                  :key="index"
                  :label="item.Unit"
                  :value="item.Unit"
                >
                </el-option>
              </el-select>
            </h4>
          </div>
          <div class="price">
            <h4
              id="unitPriceMobi"
              class="font-size-p"
              style="width: 120px; text-align: left"
            >
              x{{ new Intl.NumberFormat().format(dataProduct.ExpriceShow) }} đ
            </h4>
            <h4
              id="totalMoneyProduct"
              style="color: #c3262e; width: 120px; text-align: right"
              class="font-size-p"
            >
              = {{ new Intl.NumberFormat().format(dataProduct.TotalMoney) }} đ
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataProduct: {},
      quantityProduct: 1,
    };
  },
  props: {
    productBuy: Object,
  },
  methods: {
    btMinus() {
      if (this.dataProduct.QuantityProduct > 1) {
        this.dataProduct.QuantityProduct -= 1;
        this.dataProduct.TotalMoney =
          this.dataProduct.QuantityProduct * this.dataProduct.ExpriceShow;
        this.$emit("updateProduct");
      }
    },
    btPlus() {
      this.dataProduct.QuantityProduct += 1;
      this.dataProduct.TotalMoney =
        this.dataProduct.QuantityProduct * this.dataProduct.ExpriceShow;
      this.$emit("updateProduct");
    },
    btDelete() {
      this.$confirm(
        "Có chắc bạn muốn xóa sản phẩm này không?",
        "Xóa sản phẩm",
        {
          confirmButtonText: "Xóa",
          cancelButtonText: "Hủy",
        }
      ).then(() => {
        this.$emit("deleteProduct", this.dataProduct);
      });
    },
  },
  watch: {
    productBuy(newProduct) {
      this.dataProduct = newProduct;
      this.dataProduct.ExpriceShow = this.dataProduct.UnitLst.find(
        (p) => p.Unit == this.dataProduct.Unit
      ).Price;
      this.dataProduct.ProductUnitID = this.dataProduct.UnitLst.find(
        (p) => p.Unit == this.dataProduct.Unit
      ).ProductUnitID;
      this.dataProduct.Price = this.dataProduct.ExpriceShow;
      this.dataProduct.TotalMoney =
        this.dataProduct.QuantityProduct * this.dataProduct.ExpriceShow;
    },
    "dataProduct.Unit"(newValue) {
      this.dataProduct.ExpriceShow = this.dataProduct.UnitLst.find(
        (p) => p.Unit == newValue
      ).Price;
      this.dataProduct.ProductUnitID = this.dataProduct.UnitLst.find(
        (p) => p.Unit == newValue
      ).ProductUnitID;
      this.dataProduct.Price = this.dataProduct.ExpriceShow;
      this.dataProduct.TotalMoney =
        this.dataProduct.QuantityProduct * this.dataProduct.ExpriceShow;
      this.$emit("updateProduct");
    },
  },
  created() {
    if (this.productBuy) {
      this.dataProduct = this.productBuy;
      this.dataProduct.TotalMoney =
        this.productBuy.QuantityProduct * this.productBuy.ExpriceShow;
    }
  },
};
</script>

<style lang="scss" scoped>
.top-name {
  display: flex;
  justify-content: space-between;
}
.cart-quantity {
  display: flex;
  justify-content: space-between;
  /* background-color: #f1f1f1; */
  padding: 10px 5px;
  width: 10%;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
  border-radius: 8px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.26); */
}
.cart-quantity-mobile {
  display: flex;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 6px 0px;
  width: 80px;
  min-width: 80px;

  height: 20px;
  margin-top: 10px;
  border-radius: 8px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.26); */
}
.cart-quantity-line1 {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.25);
  margin-right: 8px;
}
.cart-quantity-line2 {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.25);
  margin-left: 8px;
}
.cart-quantity-number {
  font-weight: 500;
}
.cart-container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.cart-image {
  max-height: 70px;
}
h4 {
  margin: auto 0px;
}
h5 {
  color: rgba(0, 0, 0, 0.54);
}
.cart-checkbox {
  margin: auto 0px;
}
.cart-imageandinfo {
  width: 40%;
  display: flex;
}
button {
  border: none;
  background: none;
  cursor: pointer;
}
.cartCell-bt-delete {
  cursor: pointer;
  border: none;
  background: none;
}
@media screen and (max-width: 998px) {
  .container-web {
    display: none !important;
  }

  .container-mobile1 {
    display: block !important;
    .cart-container-mobile {
      display: flex;
      .image {
        width: 12%;
        min-width: 40px;
        display: flex;
        justify-content: center;
      }
      .cart-quantity-mobile {
        height: 15px !important;
      }
      .content {
        width: 100%;
        .price {
          display: flex;
          margin-top: 8px;
          justify-content: space-between;
        }
      }
    }
  }
  .cart-imageandinfo {
    width: 100% !important;
  }
}
@media (min-width: 998px) {
  .container-web {
    display: block !important;
  }
  .container-mobile1 {
    display: none !important;
  }
}
</style>
<style>
@media (max-width: 1200px) {
  .cart-container-price {
    width: 80% !important;
    margin: 20px auto;
    padding-top: 10px !important;
  }
}
@media (max-width: 998px) {
  .cart-quantity-mobile {
    height: 10px !important;
  }
  .cart-quantity-number .el-input__inner {
    height: 27px !important;
  }
  #unitPrice {
    margin: 10px 0 0 5px;
  }
  #unitPrice .el-select .el-input .el-input__inner {
    border: none !important;
    background: #f1f1f1;
    border-radius: 8px;
    color: black;
  }
}

@media (min-width: 998px) {
  .cart-quantity-number .el-input__inner {
    height: 40px !important;
  }
}

@media (max-width: 600px) {
  .cart-quantity-mobile {
    height: 2px !important;
    margin-top: 10px !important;
  }
  .cart-container-price {
    width: calc(100% - 40px) !important;
    margin: 20px auto;
    padding-top: 10px !important;
  }
}
.el-input-group__append,
.el-input-group__prepend {
  padding: 0px !important;
  padding-right: 20px !important;
}
@media (max-width: 768px) {
  .mt-20 {
    margin-top: 10px !important;
  }
}
</style>
