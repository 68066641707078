<template>
  <div>
    <div class="container" v-loading="loading">
      <div class="ml-mobi mt-20">
        <a class="tag" href="">Trang chủ / </a>
        <a class="tag">Thông tin sản phẩm</a>
      </div>
      <div class="info-container">
        <div class="info-image">
          <el-carousel
            ref="carousel"
            class="info-custom-image"
            arrow="never"
            :autoplay="false"
            :loop="false"
          >
            <el-carousel-item
              v-for="(item, index) in productInfo.ImgLst"
              :key="index"
            >
              <div class="container-image">
                <img class="image" :src="item.LinkImage" alt="" />
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="info-des">
          <h2 class="info-name-product">
            {{ productInfo.ProductName }}
          </h2>

          <div class="rate-layout" style="margin-top: 5px">
            <el-row :gutter="0">
              <el-col :span="1.5"
                ><div class="numberRate text-gray-bolder">5.0</div></el-col
              >
              <el-col :span="7">
                <el-rate
                  v-model="valueRate"
                  disabled
                  text-color="#ff9900"
                ></el-rate>
              </el-col>
            </el-row>
          </div>
          <div class="price-show">
            {{ new Intl.NumberFormat().format(productInfo.ExpriceShow) }} VNĐ
          </div>
          <div style="background: #f3f5f7; padding: 5px 15px; margin-top: 8px">
            <div class="flex-layout">
              <p class="title-detail font-size-p">Loại:</p>
              <p class="font-size-">{{ productInfo.Tag }}</p>
            </div>
          </div>
          <div style="background: #f3f5f7; padding: 5px 15px; margin-top: 8px">
            <div class="flex-layout">
              <p class="title-detail font-size-p">Đơn vị:</p>
              <div>
                <el-radio
                  id="selectDosageForm"
                  style="margin-left: 10px"
                  v-model="productInfo.Unit"
                  :key="index"
                  v-for="(dataPackage, index) in productInfo.UnitLst"
                  :label="dataPackage.Unit"
                  text-color="#1d974a"
                  :value="dataPackage.Unit"
                ></el-radio>
              </div>
            </div>
          </div>
          <div style="background: #f3f5f7; padding: 5px 15px; margin-top: 8px">
            <div class="flex-layout">
              <p class="title-detail font-size-p">Số lượng:</p>
              <div style="display: flex" class="">
                <div
                  class="background-gradient"
                  style="
                    text-align: center;
                    padding: 5px 8px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    color: #fff;
                  "
                  @click="btMinus"
                >
                  <i class="fa-solid fa-minus"></i>
                </div>
                <h4
                  class="intro-quantity-number"
                  style="text-align: center; width: 40px; margin: auto"
                >
                  {{ productInfo.QuantityProduct }}
                </h4>
                <div
                  class="background-gradient"
                  style="
                    text-align: center;
                    padding: 5px 8px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    color: #fff;
                  "
                  @click="btPlus"
                >
                  <i class="fa-solid fa-plus"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="flex" style="margin-top: 16px">
            <div
              @click="btAddToCart"
              style="
                border-radius: 40px;
                text-align: center;
                padding: 7px 11px;
                cursor: pointer;
                width: 200px;
                border: 1px solid #c3262e;
              "
            >
              <p class="font-size-h4" style="color: #c3262e; font-weight: 500">
                <i class="fa-solid fa-cart-shopping"></i>
                Thêm vào giỏ
              </p>
            </div>
            <div
              class="background-gradient"
              @click="btBuyNow"
              style="
                margin-left: 8px;
                border-radius: 40px;
                text-align: center;
                padding: 8px 12px;
                cursor: pointer;
                width: 200px;
              "
            >
              <p class="font-size-h4" style="color: #fff; font-weight: 500">
                Mua ngay
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container-info-drug">
        <el-tabs v-model="activeName">
          <el-tab-pane label="Mô tả sản phẩm" name="first">
            <div
              style="
                white-space: pre-wrap;
                color: rgba(0, 0, 0, 0.8);
                font-size: 0.875rem;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.7;
              "
            >
              <p style="line-height: 1.8; margin: 0">
                {{ productInfo.Detail }}
              </p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div class="info-product mt-10" v-if="productLstApi.length > 0">
        <h3 class="mt-20 color-main">Các sản phẩm liên quan</h3>
        <div
          class="info-product-container"
          style="min-height: 500px"
          v-loading="loading"
        >
          <div
            class="info-product-item"
            v-for="(count, index) in productLstApi"
            :key="index"
          >
            <producCell :productInfo="count"></producCell>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import producCell from "../body/components/productCell.vue";
import { mapActions, mapGetters } from "vuex";
import { Message } from "element-ui";
import { GetProductInfoPublic, GetProductLstPublic } from "@/api/productApi.js";
import { setProductCartLst, getProductCartLst } from "@/store/index.js";
export default {
  data() {
    return {
      idBrowser: "",
      productInfo: {},
      productLstApi: [],
      activeName: "first",
      UnitOfMeasureLst: "",
      loading: false,
      valueRate: 5,
      loading: false,
      // quantityProduct: 1,
    };
  },

  components: {
    producCell,
  },
  watch: {
    "productInfo.Unit"(newValue) {
      this.productInfo.ExpriceShow = this.productInfo.UnitLst.find(
        (p) => p.Unit == newValue
      ).Price;
    },
  },
  methods: {
    getPackageProduct() {
      document.getElementById("warningPackage").style.visibility = "hidden";
    },
    btPrev() {
      this.$refs.carousel.prev();
    },
    btNext() {
      this.$refs.carousel.next();
    },
    GetListProduct() {
      this.loading = true;
      GetProductLstPublic({
        RowspPage: 15,
        PageNumber: 1,
        ProductType: this.productInfo.ProductType,
      }).then((res) => {
        this.productLstApi = res.Data;
        this.$store.dispatch("updateProductLst", this.productLstApi);
        this.loading = false;
      });
    },
    btMinus() {
      if (this.productInfo.QuantityProduct > 1) {
        this.productInfo.QuantityProduct -= 1;
      }
    },
    btPlus() {
      this.productInfo.QuantityProduct += 1;
    },
    btBuyNow() {
      var productCartLst = getProductCartLst();
      if (productCartLst) {
        var check = productCartLst.find(
          (p) =>
            p.Unit == this.productInfo.Unit &&
            p.ProductID == this.productInfo.ProductID
        );
        if (check) {
          this.$router.push({ path: "/gio-hang" });
          return;
        }
        productCartLst.push({
          ...this.productInfo,
          UnitLst: this.productInfo.UnitLst.map((p) => {
            return {
              ...p,
              Selected: p.Unit == this.productInfo.Unit ? true : false,
            };
          }),
        });
        setProductCartLst(productCartLst);
        this.$router.push({ path: "/gio-hang" });
      } else {
        productCartLst = [];
        productCartLst.push({
          ...this.productInfo,
          UnitLst: this.productInfo.UnitLst.map((p) => {
            return {
              ...p,
              Selected: p.Unit == this.productInfo.Unit ? true : false,
            };
          }),
        });
        setProductCartLst(productCartLst);
        this.$router.push({ path: "/gio-hang" });
      }
    },
    btAddToCart() {
      var productCartLst = getProductCartLst();
      if (productCartLst) {
        var check = productCartLst.find(
          (p) =>
            p.Unit == this.productInfo.Unit &&
            p.ProductID == this.productInfo.ProductID
        );
        if (check) {
          Message.warning("Sản phẩm đã tồn tại trong giỏ hàng");
          return;
        }
        productCartLst.push({
          ...this.productInfo,
          UnitLst: this.productInfo.UnitLst.map((p) => {
            return {
              ...p,
              Selected: p.Unit == this.productInfo.Unit ? true : false,
            };
          }),
        });
        setProductCartLst(productCartLst);
        Message.success("Thêm sản phẩm thành công");
      } else {
        productCartLst = [];
        productCartLst.push({
          ...this.productInfo,
          UnitLst: this.productInfo.UnitLst.map((p) => {
            return {
              ...p,
              Selected: p.Unit == this.productInfo.Unit ? true : false,
            };
          }),
        });
        setProductCartLst(productCartLst);
        Message.success("Thêm sản phẩm thành công");
      }
    },
    getProductInfo(id) {
      this.loading = true;
      GetProductInfoPublic({
        ProductID: id,
      }).then((res) => {
        this.productInfo = {
          ...res.Data,
          LinkImage: res.Data.ImgLst[0].LinkImage ?? "",
          QuantityProduct: 1,
          ExpriceShow: res.Data.UnitLst[0].Price,
        };
        this.GetListProduct();
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapActions(["updateProductLst", "updateProductInCart"]),
    ...mapGetters(["productLst"]),
  },

  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.getProductInfo(this.$route.params.id);
      }
    );
    this.getProductInfo(this.$route.params.id);
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: block;
}
.price-show {
  background: #f3f5f7;
  padding: 16px;
  margin-top: 8px;
  font-size: 24px;
  font-weight: 500;
  color: #e76f00;
}
.tag {
  text-decoration: none;
  color: #c3262e;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.info-container {
  display: flex;
  background-color: #fff;
  margin: 20px auto;
  border-radius: 16px;
  padding: 50px;

  .info-image {
    padding: 20px;
    border: 1.5px solid #c3262e;
    border-radius: 8px;
    width: 380px;
    .container-image {
      height: 300px;
      position: relative;
    }
  }
  .info-des {
    margin: 0px 20px;
    position: relative;
    width: calc(100% - 380px);
     .title-detail {
        width: 150px;
        font-weight: 500;
      }
  }
}
.info-image button {
  font-size: 36px;
  background-color: #fff;
  border: none;
  color: #e76f00;
  height: 60px;
  margin-top: 120px;
}

.secondary-images {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 20px;
  border-radius: 8px;
  max-height: 80px;
  max-width: 80px;
  padding: 10px;
  border: 1.5px solid #23944f;
  text-align: center;
  cursor: pointer;
}
.font-size- {
  width: calc(100% - 150px);
}
.font-size-p {
  width: calc(100% - 100px);
}
.image {
  max-width: 100%;
  height: auto;
  width: auto;
  scale: 1.2;
  max-height: 300px;
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
}
.info-image-line {
  margin: 0px 20px 40px 20px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.25);
}

/* Giá thuốc, mô tả sản phẩm */

.info-name-product {
  font-size: 22px;
  font-weight: 600;
  color: #c3262e;
}
.info-frame-dash {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 420px;
  padding: 10px;
  border: dashed 2px rgba(0, 0, 0, 0.25);
}
.info-text-des {
  display: flex;
  margin-top: 8px;
}
.info-title {
  margin-left: 0px;
  /* margin: auto 0px; */
  min-width: 90px;
  color: rgba(0, 0, 0, 0.54);
}
.intro-price {
  color: #1d974a;
}
.intro-policy {
  margin: 8px 0px;
  padding: 4px 12px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.151);
}
.intro-policy2 {
  margin: 8px 0px 8px 25px;
  padding: 4px 12px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.151);
}
.info-frame-quantity {
  display: flex;
  /* justify-content: inline-block; */
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}
.intro-quantity {
  display: flex;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 5px 15px;
  margin-left: 10px;
  width: 150px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.26);
}
.intro-quantity button {
  border: none;
  color: #c3262e;
}
.intro-quantity-number {
  font-weight: 400;
}

.intro-quantity-line1 {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.54);
  margin-right: 16px;
}
.intro-quantity-line2 {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.54);
  margin-left: 16px;
}
.intro-frame-bt {
  margin-top: 20px;
}
.intro-bt-add {
  width: 150px;
  color: #fff;
  background-color: #417ae2;
  border: none;
  border-radius: 8px;
  height: 40px;
  font-size: 14px;
}
.intro-bt-buy {
  margin-left: 12px;
  width: 150px;
  color: #fff;
  background-color: #da3732;
  border: none;
  border-radius: 8px;
  height: 40px;
  font-size: 14px;
}
/* Các sản phẩm liên quan */
.info-product-container {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  margin: 0px -8px;
}
.frame-button {
  text-align: center;
}
.bt-seemore {
  padding: 10px 30px;
  border-radius: 8px;
  color: #c3262e;
  font-size: 16px;
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid #c3262e;
}
.bt-seemore:hover {
  background-color: #c3262e;
  color: #fff;
}
button {
  background: none;
  background-color: none;
  cursor: pointer;
}
.container-info-drug {
  background: #fff;
  border-radius: 16px;
  min-height: 200px;
  margin-top: 16px;
  padding: 20px 28px;
  padding-bottom: 40px;
  // width: 60%;
}

/* Dien thoai */
.info-mobile-image {
  margin: 10px;
  border-radius: 8px;
  text-align: center;
  background: #fff;
}

.image-mobile {
  width: auto;
  height: 200px;
  border-radius: 8px;
}
.info-frame-mobile-dash {
  margin-top: 15px;
  margin-bottom: 15px;
  width: calc(100% - 25px);
  padding: 10px;
  border: dashed 2px rgba(0, 0, 0, 0.25);
}
.info-name-moblie-product {
  font-size: 22px;
}
.info-mobile-des {
  background: #fff;
  padding: 16px 12px;
  margin: 8px;
  margin-top: 0px;
  border-radius: 8px;
}
.flex-layout {
  display: flex;
  margin: 10px 0px;
}
@media screen and (max-width: 678px) {
  .info-container {
    display: block;
    margin: 8px;
    padding: 16px;
    .info-image {
      padding: 20px;
      border: 1.5px solid #c3262e;
      border-radius: 8px;
      // width: 380px;
      width: auto;
      .container-image {
        height: 300px;
        position: relative;
      }
    }
    .info-des {
      margin: 20px 0px;
      position: relative;
      width: calc(100%);
      .title-detail {
        width: 100px;
        font-weight: 500;
      }
    }
  }
  .container-info-drug {
    min-height: 200px;
    margin-top: 16px;
    padding: 8px 16px;
    padding-bottom: 40px;
    // width: 60%;
  }
  .info-product {
    padding: 0 8px;
    .info-product-container {
      grid-template-columns: 50% 50%;
    }
  }
  .ml-mobi{
    margin-left: 8px;
  }
}
</style>
<style>
#selectDosageForm {
  margin-right: 15px !important;
}
.el-radio.is-bordered.is-checked {
  color: #c3262e !important;
  border-color: #c3262e !important;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #c3262e !important;
}
.el-radio__input.is-checked .el-radio__inner {
  background: #c3262e !important;
  border-color: #c3262e !important;
}
.el-dialog__footer {
  text-align: center !important;
}

#buttonAdd {
  background: #c3262e !important;
  width: 90% !important;
}
.info-custom-image {
  height: 330px;
  width: 260px;
  margin: 0px auto;
}
.info-custom-image .el-carousel__container {
  position: relative;
  height: 300px;
}
.info-custom-image ul li button {
  background-color: #e76f00 !important;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}
@media (max-width: 1100px) {
  .info-custom-mobile-image .el-carousel__container {
    height: 200px;
  }
}
@media (max-width: 1200px) {
  .container-info-drug {
    margin: 10px !important;
  }
}
/* @media (max-width: 768px) {
  .layout-buy-now-mobile {
    display: block !important;
  }
  .layout-buy-now {
    display: none !important;
  }
} */
@media (max-width: 600px) {
  .text-see-more {
    display: none !important ;
  }
  .text-add-cart {
    display: none !important ;
  }
  .glyphicon {
    display: block !important;
  }
  .button-product {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
.container-info-drug .el-tabs__item.is-active {
  color: #c3262e !important;
}
.container-info-drug .el-tabs__item:hover {
  color: #c3262e !important;
}
.container-info-drug .el-tabs__active-bar {
  background-color: #c3262e !important;
}
/* .el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
} */
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
