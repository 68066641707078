<template>
  <div style="min-height: 500px" v-loading="loadingFull">
    <div class="container">
      <div class="mt-20">
        <a href="">Trang chủ </a>
      </div>
      <div class="productLst-frame" v-if="moreProductLst">
        <div class="productLst-right">
          <div class="productLst-container">
            <div
              class="productLst-item"
              v-for="product in moreProductLst"
              :key="product.ProductID"
            >
              <producCell :productInfo="product"></producCell>
            </div>
          </div>

          <div class="productLst-container" v-if="productSearch">
            <div
              class="productLst-item"
              v-for="product in productSearch"
              :key="product.ProductID"
            >
              <producCell :productInfo="product"></producCell>
            </div>
          </div>

          <div
            class="text-center"
            v-if="moreProductLst.length === 0 && productSearch.length === 0"
          >
            <p style="color: #454545; padding: 60px">
              Rất tiếc chúng tôi chưa có sản phẩm nào thuộc danh mục này
            </p>
          </div>

          <div class="frame-button" v-if="isShowBtMore" @click="btSeeMore">
            <el-button class="bt-seemore" :loading="loading"
              >Xem thêm</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <div class="container-mobile">
      <!-- <div>Anh thanhf</div> -->
      <div class="mt-10">
        <a href="" style="font-size: 14px">Trang chủ </a>
      </div>
      <div class="container-mobile-lstproduct">
        <el-row class="">
          <el-col
            :lg="6"
            :md="8"
            :sm="8"
            :xs="12"
            v-for="product in this.moreProductLst"
            :key="product.ProductID"
            class=""
          >
            <producCell :productInfo="product"></producCell>
          </el-col>
        </el-row>
        <div
          class="frame-button"
          style="margin-bottom: 30px"
          v-if="isShowBtMore"
          @click="btSeeMore"
        >
          <el-button class="bt-seemore" :loading="loading">Xem thêm</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import producCell from "../body/components/productCell.vue";
import { GetProductLstPublic } from "@/api/productApi.js";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      productLstMobile: [],
      pageNunber: 1,

      loadingFull: true,
      isShowBtMore: true,
      search: "",
      numberRow: 15,
      keyProduct: 0,
      loading: false,
      productSeeMoreLst: [],
      productLst: [],
    };
  },
  components: {
    producCell,
  },
  computed: {
    ...mapActions(["updateProductSearch"]),
    ...mapActions(["updateMoreProductLst"]),
    ...mapGetters(["productSearch"]),
    ...mapGetters(["moreProductLst"]),
  },
  methods: {
    getProductLstSearch() {
      if (this.productSearch.length === 0) {
        this.loadingFull = true;
        GetProductLstPublic({
          Search: this.search,
          RowspPage: 1000,
          PageNumber: 1,
        }).then((res) => {
          this.loadingFull = false;
          this.keyProduct = this.keyProduct + 1;
          this.productLst = res.data.ProductPostLst;
          console.log("search", this.productLst);
          this.$store.dispatch("updateProductSearch", this.productLst);
          this.isShowBtMore = false;
        });
      } else {
        this.productLst = this.productSearch;
      }
    },
    getProductLst() {
      this.loading = true;

      GetProductLstPublic({
        Search: this.search,
        RowspPage: this.numberRow,
        PageNumber: this.pageNunber,
      }).then((res) => {
        this.loading = false;
        this.loadingFull = false;
        // var productCheck = this.productLst
        this.productLst = this.moreProductLst.concat(
          res.Data.map((p) => {
            return {
              ...p,
              LinkImage: p.ImgLst[0].LinkImage ?? "",
            };
          })
        );

        this.$store.dispatch("updateMoreProductLst", this.productLst);
        if (this.moreProductLst.length < 14) {
          this.isShowBtMore = false;
        } else {
          this.isShowBtMore = true;
        }
        if (res.data.ProductPostLst.length < 14) {
          this.isShowBtMore = false;
        } else {
          this.isShowBtMore = true;
        }
      });
    },
    btSeeMore() {
      this.pageNunber += 1;
      this.getProductLst();
    },
    checkLoadItem() {
      this.pageNunber = 1;

      if (this.moreProductLst.length === 0) {
        this.loading = true;
        GetProductLstPublic({
          Search: "",
          RowspPage: this.numberRow,
          PageNumber: this.pageNunber,
        }).then((res) => {
          this.productLst = [];

          this.productLst = res.Data.map((p) => {
            return {
              ...p,
              LinkImage: p.ImgLst[0].LinkImage ?? "",
            };
          });
          console.log("get list", this.moreProductLstj);
          this.loading = false;
          this.$store.dispatch("updateMoreProductLst", this.productLst);
          if (this.productLst.length < 14) {
            this.isShowBtMore = false;
          } else {
            this.isShowBtMore = true;
          }
        });
      }

      this.loadingFull = false;
    },
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch("updateMoreProductLst", []);
    },
  },
  created() {
    // var a = window.matchMedia("(min-width: 1200px)");
    // var x = window.matchMedia("(min-width: 768px)");
    // var y = window.matchMedia("(max-width: 768px)");
    // if (a.matches) {
    //   this.numberRow = 16;
    // }
    // if (x.matches) {
    //   this.numberRow = 15;
    // }
    // if (y.matches) {
    //   this.numberRow = 16;
    // }
console.log('window.location.href',window.location.href);
    if (window.location.href.indexOf("kiem") > -1) {
      this.getProductLstSearch();
    } else {

      this.checkLoadItem();
    }

    this.$watch(
      () => this.$route.params,
      () => {
        this.checkLoadItem();
      }
    );
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #c3262e;
}
ul {
  padding: 13px 20px;
  margin: 0px;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.productLst-frame {
  display: flex;
  margin-top: 13px;
}

.productLst-frame-menu {
  background-color: #fff;
  margin-right: 20px;
  margin-top: 7px;
  width: 20%;
  height: 320px;
  padding-top: 2px;
  padding-bottom: 0px !important;
  border-radius: 8px;
}

.productLst-frame-menu ul:hover {
  color: #fff;
  background-color: #c3262e;
}
.productLst-right {
  width: 100%;
}

.productLst-container {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.productLst-item {
  margin-top: 0px;
  height: auto;
}
.frame-button {
  text-align: center;
}
.bt-seemore {
  padding: 10px 30px;
  border-radius: 8px;
  color: #c3262e;
  font-size: 16px;
  margin-top: 20px;
  background-color: #fff;
  border: 1px solid #c3262e;
}
.bt-seemore:hover {
  background-color: #c3262e;
  color: #fff;
}
/* Mobile */
.container-mobile-lstproduct {
  width: 100%;
  /* height: 400px; */
  /* background: #C3262E; */
  /* margin: auto 8px;
  padding-bottom: 30px; */
  place-items: center;
}
</style>
