<template>
  <div class="navbar navbar-container background-gradient">
    <div class="container">
      <div class="pt-5 pb-5 flex-space">
        <div style="display: flex">
          <button class="navbar-bt-address pr-15" @click="btClickRecruit">
            <i class="fa-solid fa-bullhorn"></i> Tin tức - Bài viết
          </button>
        </div>
        <div class="navbar-right">
          <button class="navbar-bt-address pr-15" @click="btClickProducts">
            <i class="fa-solid fa-plane"></i> Sản phẩm
          </button>
          <button class="navbar-bt-address pr-15" @click="btClickShops">
            <i class="fa-solid fa-location-dot"></i> Địa chỉ
          </button>
          <button class="navbar-bt-address pr-15" @click="openShareHolder">
            <i class="fa-solid fa-users-line"></i> Chính sách
          </button>
        </div>
      </div>
      <div class="line"></div>
      <div class="navbar-container-bottom">
        <div class="container-logo" @click="btHome">
          <img class="navbar-logo" src="@/assets/images/techsso.png" alt="" />
        </div>

        <div class="navbar-frame-search">
          <div style="display: flex">
            <button class="navbar-iconSearch">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
            <div style="width: 10px; height: 40px; margin-left: -5px"></div>
            <el-input
              class="navbar-mobile-input-search"
              v-model="searchProduct"
              :clear="true"
              placeholder="Bạn muốn tìm sản phẩm gì?"
              :loading="loading"
              :clearable="true"
              @keyup.enter.native="getProductSearch"
              v-debounce="getProductSearch"
            >
            </el-input>
          </div>
          <div class="navbar-itemSearch">
            <div
              v-if="this.productSearchLst.length > 0"
              style="height: 20px"
            ></div>
            <div
              v-for="(item, index) in this.productSearchLst"
              :key="index"
              :label="item.ProductName"
              :value="item.ProductName"
              style="padding-top: 8px"
            >
              <div
                style="display: flex; padding: 0 8px; cursor: pointer"
                @click="btProductInfo(item)"
              >
                <div style="width: 36px">
                  <img
                    v-if="item.LinkImage"
                    :src="item.LinkImage"
                    alt=""
                    style="
                      height: 24px;
                      width: 24px;
                      border: 1px solid #95b995;
                      border-radius: 6px;
                    "
                  />
                  <img
                    v-else
                    src="@/assets/images/imageempty.png"
                    alt=""
                    style="height: 24px; width: auto"
                  />
                </div>

                <span style="float: left; font-size: 14px; padding-top: 4px">{{
                  item.ProductName
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="background-search"
            v-if="this.productSearchLst.length > 0"
            @click="clearSurgget"
          ></div>
        </div>

        <div>
          <div class="navbar-frame-option">
            <!-- Hotline -->
            <div class="navbar-container-option">
              <div class="navbar-frame-icon">
                <i class="fa-solid fa-phone navbar-layout-icon"></i>
              </div>
              <div class="ml-5">
                <p class="custom-p">Hotline mua hàng</p>
                <h6 class="mt-5 font-size-h6" style="font-weight: 300">
                  0353.155.486
                </h6>
              </div>
            </div>
            <div class="line-horizontal"></div>

            <!-- Tham vấn dược sĩ -->
            <div class="navbar-container-option" @click="btMessenger">
              <div class="navbar-frame-icon text-center">
                <i class="fa-solid fa-user navbar-layout-icon"></i>
              </div>
              <div>
                <h6 class="pl-10 font-size-h6" style="font-weight: 300">
                  Tư vấn
                </h6>
                <h6 class="pl-10 font-size-h6" style="font-weight: 300">
                  mua hàng
                </h6>
              </div>
            </div>
            <div class="line-horizontal"></div>
            <!-- Giỏ hàng -->
            <div class="navbar-container-option" @click="btCart">
              <div class="navbar-frame-icon">
                <i class="fa-solid fa-cart-shopping navbar-layout-icon"></i>
              </div>
              <div>
                <h6 class="mt-10 ml-10 font-size-h6" style="font-weight: 300">
                  Đặt hàng
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-history" v-if="isShowHistory">
        <div class="frame-history">
          <img class="image-history" src="@/assets/images/history.png" alt="" />
          <p style="width: 300px; margin: 16px auto; color: #454545">
            Nhập số điện thoại bạn dùng để mua hàng tại Techsso
          </p>
          <el-input
            placeholder="Nhập số điện thoại"
            v-model="inputPhoneNunber"
            style="width: 350px"
          ></el-input>
          <div
            style="display: flex; margin: 16px auto; justify-content: center"
          >
            <button class="bt-history" @click="btHiddenHistory">Hủy</button>
            <button
              class="bt-history"
              style="background: #c3262e; color: #fff"
              @click="notidication"
            >
              Tiếp tục
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-mobile">
      <div class="navbar-mobile">
        <div class="narbar-frame-top">
          <button @click="openMenuMobile" class="navbar-mobile-menu">
            <i class="fa-solid fa-bars"></i>
          </button>
          <img
            class="navbar-mobile-logo"
            src="@/assets/images/techsso.png"
            @click="btHome"
            style="cursor: pointer"
          />
          <button
            style="cursor: pointer"
            @click="btCart"
            class="navbar-mobile-cart"
          >
            <i class="fa-solid fa-cart-shopping"></i>
          </button>
        </div>
        <div>
          <div class="narbar-mobile-bottom">
            <div class="navbar-mobile-frame-search">
              <div style="display: flex">
                <button class="navbar-mobile-iconSearch">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </button>
                <el-input
                  class="navbar-mobile-input-search"
                  v-model="searchProduct"
                  :clear="true"
                  placeholder="Bạn muốn tìm sản phẩm gì?"
                  :loading="loading"
                  v-debounce="getProductSearch"
                >
                </el-input>
              </div>
              <div class="navbar-mobile-itemSearch">
                <div
                  v-if="this.productSearchLst.length > 0"
                  style="height: 20px"
                ></div>
                <div
                  v-for="(item, index) in this.productSearchLst"
                  :key="index"
                  :label="item.ProductName"
                  :value="item.ProductName"
                  style="padding-top: 8px"
                >
                  <div
                    @click="btProductInfo(item)"
                    style="display: flex; padding: 0 8px"
                  >
                    <div style="width: 36px">
                      <img
                        v-if="item.LinkImage"
                        :src="item.LinkImage"
                        alt=""
                        style="height: 24px; width: auto"
                      />
                      <img
                        v-else
                        src="@/assets/images/imageempty.png"
                        alt=""
                        style="height: 24px; width: auto"
                      />
                    </div>

                    <span
                      style="float: left; font-size: 14px; padding-top: 4px"
                      >{{ item.ProductName }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Message } from "element-ui";
import { GetProductLstPublic } from "@/api/productApi.js";
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      searchProduct: "",
      gettingLocation: false,
      errorStr: null,
      productSearchLst: [],
      loading: true,
      isShowHistory: false,
      key: 0,
      gleve1: "tim-kiem",
    };
  },
  watch: {
    searchProduct() {
      if (this.searchProduct == "") {
        this.productSearchLst = [];
      } else {
        // this.getProductSearch();
      }
    },
  },
  directives: {
    debounce: {
      inserted: function (el, binding) {
        let timeout = null;
        el.addEventListener("input", function () {
          clearTimeout(timeout);
          timeout = setTimeout(function () {
            binding.value();
          }, 500); // Adjust debounce delay as per your requirements
        });
      },
    },
  },
  computed: {
    ...mapGetters(["areaUser", "location"]),
    ...mapActions(["updateAreaUser", "updateLocation"]),
  },
  methods: {
    openShareHolder() {
      this.$router.push("/chinh-sach");
    },

    openMenuMobile() {
      this.key = this.key + 1;
      this.$emit("emitMenuSlide", this.key);
      console.log("open menu", this.key);
    },

    btClickRecruit() {
      this.$router.push({ path: "/tin-tuc-bai-viet" });
    },
    btMessenger() {
      window.open("https://www.facebook.com/messages/t/100068855400399/");
    },
    btClickShops() {
      this.$router.push({ path: "/dia-chi" });
    },
    btClickProducts() {
      this.$router.push({ path: "/danh-sach-san-pham" });
    },
    btHome() {
      this.$router.push({ path: "/" });
    },
    btCart() {
      this.$router.push({ path: "/gio-hang" });
    },
    getProductSearch() {
      GetProductLstPublic({
        Search: this.searchProduct,
        RowspPage: 6,
        PageNumber: 1,
      }).then((res) => {
        this.loading = false;
        this.productSearchLst = res.Data.map((item) => {
          return {
            ...item,
            LinkImage: item.ImgLst[0].LinkImage ?? "",
          };
        });
        if (this.searchProduct == "") {
          this.productSearchLst = [];
        }
      });
    },
    btProductInfo(item) {
      this.productSearchLst = [];
      this.$router.push({ path: "/thong-tin-san-pham/" + item.ProductID });
    },
    clearSurgget() {
      this.productSearchLst = [];
    },
    btHistory() {
      this.isShowHistory = true;
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    btHiddenHistory() {
      this.isShowHistory = false;
      document.getElementsByTagName("body")[0].style.overflow = "visible";
    },
    notidication() {
      this.$notify({
        title: "Hihi",
        message: "Đã có gì đâu mà bấm :p",
        type: "success",
      });
    },
  },
  created() {
    console.log("Anhthanhf");
  },
};
</script>

<style scoped>
.navbar-container {
  height: 150px;
}
@media (max-width: 1200px) {
  .navbar-container {
    height: 120px;
  }
}

.navbar-bt-address {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
}
.line {
  background-color: #fff;
  height: 1px;
}

.flex {
  display: flex;
}

.flex-space {
  display: flex;
  justify-content: space-between;
}

.navbar-right {
  text-align: right;
}

.navbar-container-bottom {
  display: flex;
  justify-content: space-evenly;
  position: relative;
}
.container-logo {
  background: #fff;
  border-radius: 12px;
  margin: 18px auto;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.25);
  margin-left: 20px;
}
.navbar-logo {
  height: 64px;
  /* max-width: 80px; */
  width: auto;
  margin: 8px;
  background: #fff;
}

.custom-p {
  font-size: 12px;
}

.container-history {
  position: absolute;
  top: 198px;
  left: 0;
  right: 0;
  /* bottom: 0; */
  height: 100vh;
  background: rgba(0, 0, 0, 0.54);
  z-index: 21;
}
.frame-history {
  /* height: 400px; */
  background: #fff;
  width: 50%;
  margin: 0 auto;
  border-radius: 0 0 20px 20px;
  text-align: center;
  /* padding: 30px; */
}
.image-history {
  margin-top: 20px;
  width: 160px;
  height: auto;
}
.bt-history {
  width: 110px;
  height: 40px;
  margin: 0 8px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.15);
  border: none;
  margin-bottom: 30px;
  cursor: pointer;
}
.navbar-frame-location {
  width: 180px;
  background-color: rgba(0, 0, 0, 0.151);
  border-radius: 8px;
  margin-top: 25px;
  margin-right: 25px;
}
.navbar-frame-area {
  display: flex;
  justify-content: center;
  padding: 15px;
  padding-top: 5px;
}
.navbar-frame-search {
  position: relative;
  width: 30%;
  margin: auto;
  margin-top: 36px;
  z-index: 310;
}
.navbar-iconSearch {
  border: none;
  background-color: #fff;
  width: 50px;
  height: 40px;
  border-radius: 20px 0 0 20px;
  color: rgb(94, 94, 94);
  margin-left: 16px;
  margin-right: -2px !important;
}
.navbar-itemSearch {
  position: absolute;
  left: 16px;
  margin-top: -20px;
  background: #fff;
  width: calc(100% - 28px);
  z-index: -1;
  padding: 0px 0px 12px 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 20px 20px;
}
.el-input-group__append {
  border: none;
  border-color: none !important;
  background-color: #fff !important;
  color: none;
}
.navbar-frame-option {
  background-color: rgba(0, 0, 0, 0.15);
  height: 40px;
  margin: 15px auto;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 20px;
  margin-left: 40px;
  /* margin-right: -10px; */
}
.navbar-container-option {
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.navbar-frame-icon {
  height: 40px !important;
  width: 40px !important;

  background-color: #fff;
  border-radius: 20px;
}
.navbar-layout-icon {
  color: #c3262e;
  margin-top: 12px;
}
.line-horizontal {
  width: 1px;
  height: 40px;
  margin: 0px 20px;

  background-color: #fff;
}
.navbar-bt-search {
  height: 40px;
  width: 40px;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #c3262e;
  border-radius: 4px;
  margin-top: 36px;
  margin-left: 0.5px;
}
.background-search {
  position: fixed;
  /* background: #454545; */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -2;
}
/* Điện thoại */

.navbar-mobile-menu {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}
.navbar-mobile-menu:hover {
  opacity: 0.7;
}
.navbar-mobile-logo {
  margin-top: -8px;
  height: 40px;
  width: auto;
  background: #fff;
  padding: 4px;
  border-radius: 8px;
}
.navbar-mobile-cart {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
}
.narbar-frame-top {
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
.navbar-mobile-frame-search {
  position: relative;
  /* background: #fff; */
  width: calc(100% - 16px);
  z-index: 350;
}
.narbar-mobile-bottom {
  display: flex;
  justify-content: space-between;
}

.navbar-mobile-input-search {
  margin: 0;
  width: 100%;
  margin-left: -5px !important;
}
.navbar-mobile-iconSearch {
  border: none;
  background-color: #fff;
  width: 50px;
  height: 40px;
  border-radius: 20px 0 0 20px;
  color: rgb(94, 94, 94);
  margin-left: 16px;
  /* margin-right: 15px; */
}
.navbar-mobile-itemSearch {
  position: absolute;
  left: 16px;
  margin-top: -20px;
  background: #fff;
  width: calc(100% - 28px);
  z-index: -1;
  padding: 0px 0px 12px 12px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 20px 20px;
}
.navbar-mobile-location {
  margin: 10px 20px;
  margin-bottom: 0px;
  display: flex;
  color: #c3262e;
  font-size: 14px;
}
</style>
<style>
.navbar-mobile-input-search .el-input__inner {
  border: none;
  border-radius: 0 20px 20px 0;
  padding: 0px;
}
.navbar-mobile-iconSearch {
  margin-right: -5px !important;
}
</style>
