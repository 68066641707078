<template>
  <div class="container">
    <div class="genui-container">
      <h1>CHÍNH SÁCH HÀNG CHÍNH HÃNG</h1>
      <div style="text-align: center">
        <img src="~@/assets/images/camket.jpeg" alt="" />
      </div>
      <div class="mt-20">
        <p>
          Cám ơn sự quan tâm của bạn đến với sản phẩm của Techsso. Để tạo được
          niềm tin của khách hàng ngoài chất lượng của sản phẩm thì còn phụ
          thuộc vào thái độ phục vụ, dịch vụ sau bán hàng và thực hiện những cam
          kết của chúng tôi đối với khách hàng.<br /><br />
          <strong style="font-size: 20px; color: red"
            >Cam kết về sản phẩm, giá cả và chất lượng</strong
          >
          <br />
          <br />
          1. Bán hàng không mập mờ. Luôn trả lời cho khách hàng biết sản phẩm có
          hay không có, còn hay đã bán.<br />
          2. Hoàn tiền 100% cho khách hàng nếu sản phẩm bị thất lạc hoặc do sai
          sót của Techsso.vn dẫn đến giao dịch không thành công.<br />
          3. Hoàn
          tiền nếu sản phẩm bị lỗi do lỗi của nhà sản xuất.<br />
          4. Luôn cung
          cấp sản phẩm mới đúng chất lượng của nhà sản xuất, <br />
          5. Đúng như
          hình ảnh và thông tin đăng tải theo đúng mức giá được niêm yết.<br />
          6. Giữ bí mật tuyệt đối thông tin của khách hàng. Không chia sẻ cho các
          cá nhân và tổ chức khác.<br />
          7. Bán đúng giá và luôn cố gắng bán giá
          thấp nhất có thể so với các sản phẩm tương tự đang có trên thị
          trường.<br />
          8. Luôn cập nhật và thông báo về các sản phẩm mới nhất,
          model mới nhất từ chính hãng: để khách hàng không mua phải những sản
          phẩm tồn kho, kém chất lượng. <br />
          9. Cam kết chỉ nhận những gì mình
          đáng được hưởng:Xin hoàn tiền đối với các sản phẩm, dịch vụ mà chúng
          tôi cung cấp không đảm bảo với chất lượng, yêu cầu của khách hàng đề
          ra. Chúng tôi chỉ nhận những gì mình đáng được hưởng khi mang lại lợi
          ích cho bạn.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  margin-top: 10px;
  text-align: center;
  color: #e35a14;
}
p {
  font-size: 16px;
  line-height: 1.4;
}
.genui-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  padding: 40px;
  margin-top: 20px;
}
</style>