<template>
  <div>
    <!-- <megamenu/> -->
    <banner />
    <userCare />
    <productHome />
  </div>
</template>

<script>
// import megamenu from '../views/header/menu/index.vue'
import banner from "../views/header/banner/banner.vue"
import userCare from "../views/body/usercare/user-care.vue";
import productHome from "../views/body/productHome.vue";

export default {
  components: {
    // megamenu,
    banner,
    userCare,
    productHome,
  },
};
</script>

<style>
</style>