<template>
  <div class="producCell-frame" style="position: relative">
    <div class="rate-layout">
      <el-rate
        id="rate-product-cell"
        v-model="valueRate"
        disabled
        text-color="#ff9900"
      ></el-rate>
    </div>
    <div class="productCell-image" @click="btSeeInfoProduct">
      <img
        v-if="dataProduct.ImgLst.length > 0"
        class="producCell-frame-image"
        :src="dataProduct.ImgLst[0].LinkImage"
        alt=""
      />
      <img
        v-else
        class="producCell-frame-image"
        src="@/assets/images/imageempty.png"
        alt=""
      />
      <img class="productCell-logo" src="@/assets/images/techsso.png" alt="" />
    </div>
    <div class="productCell-nameprice" @click="btSeeInfoProduct">
      <h4 class="pb-5 productCell-name font-size-h5">
        {{ dataProduct.ProductName }}
      </h4>

      <p class="font-size-h6">Loại: {{ dataProduct.Tag }}</p>

      <!-- <p class="font-size-h6" style="text-align: start; padding-top: 2px">
        Quy cách đóng gói: {{ dataProduct.UnitOfMeasure }}
      </p> -->
      <h3 class="productCell-price font-size-h4">
        {{ new Intl.NumberFormat().format(dataProduct.Exprice) }} đ/{{
          dataProduct.Unit
        }}
      </h3>
    </div>

    <div
      class="pb-20"
      style="
        display: flex;
        margin: auto;
        justify-content: center;
        padding: 10px;
        margin-bottom: 5px;
        padding-top: 5px;
      "
    >
      <!-- <el-button type="primary" size="medium" icon="el-icon-notebook-2"
        >Xem chi tiết</el-button
      > -->

      <button class="button-product" @click="btSeeInfoProduct">
        <p
          class="font-size-h7 text-add-cart"
          style="font-weight: 500; z-index: 100; cursor: pointer"
        >
          Đặt mua
        </p>
        <i
          class="fa-solid fa-cart-shopping glyphicon"
          style="display: none"
        ></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataProduct: {},
      valueRate: 5,
      idBrowser: "",
    };
  },
  props: {
    productInfo: Object,
  },

  methods: {
    btSeeInfoProduct() {
      this.$router.push({
        path: "/thong-tin-san-pham/" + this.productInfo.ProductID,
      });
    },
  },
  created() {
    if (this.productInfo) {
      this.dataProduct = this.productInfo;
    }
  },
};
</script>
<style lang="scss" scoped>
.producCell-frame {
  margin: 8px;
  background: #ffffff;
  position: relative;
  border-radius: 16px;
  border: 1px solid #ffffff00;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}
.producCell-frame:hover {
  border: 1px solid #e4803a;
  .button-product {
    color: #fff !important;
    background: #c3262e !important;
  }
}

.productCell-image {
  text-align: center;
  position: relative;
  height: 220px;
}
.productCell-logo {
  position: absolute;
  height: 40px;
  left: 10px;
  top: 10px;
  opacity: 0.8;
}
.button-product {
  border: none;
  padding: 8px 16px;
  color: #c3262e;
  border-radius: 50px;
  width: 100%;
  font-weight: 500;
}

.productCell-nameprice {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
}
.productCell-name {
  font-weight: 400;
  height: 34px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #020b27;
}
.productCell-price {
  font-weight: 500;
  color: #c3262e;
  margin-top: 8px;
}
.producCell-frame-image {
  // margin: 10px auto;
  margin-top: 10px;
  // margin-bottom: 10px;
  height: auto;
  border-radius: 16px;
  max-width: 80%;
  max-height: 200px;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.el-button--primary {
  background-color: #417ae2 !important;
  border-color: #417ae2 !important;
  width: 84%;
}
.layout-add-cart {
  position: absolute;
  background: #fff;
  bottom: 5px;
}
@media (max-width: 576px) {
  .producCell-frame-image {
    height: 150px;
  }
  .productCell-nameprice {
    padding-bottom: 0px;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
.rate-layout {
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>

<style>
.el-rate__icon {
  font-size: 16px !important;
}
.el-rate__item {
  width: 15px !important;
}
</style>
