<template>
  <div>
    <div class="menu-container">
      <div class="menu-container-top text-center">
        <div
          class="menu-frame-menu"
          v-for="(item, index) in routesMenu"
          :key="index"
        >
          <div @click="btPush(item)">
            <span class="menu-title"> {{ item.name }} </span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container-menu-mobile"
      id="fullMenu"
      style="
        z-index: 500;
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      "
    >
      <div class="mobile-nav txt-menu" id="menu-mobile" style="z-index: 1000">
        <ul
          style="
            margin-top: 0px;
            display: block;
            position: none;
            list-style: none;
            padding-left: 10px;
          "
        >
          <li
            class="navbar-item-mobile"
            v-for="(item, index) in routesMenu"
            :key="index"
            @click="btPush(item)"
          >
            <a style="width: 85%">
              <p class="navbar-title-mobile txt-menu" id="title-product">
                {{ item.name }}
              </p>
            </a>
          </li>
        </ul>
      </div>
      <div class="clear-fix-layout" id="clear-fix" @click="closeNavMenu"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { constantRoutes } from "@/router";
export default {
  data() {
    return {
      listGroup: [],
      dataAllGroup: [],
    };
  },
  props: {
    openMenuSlide: Number,
  },

  computed: {
    ...mapActions(["updateMoreProductLst"]),
    routesMenu() {
      return constantRoutes.filter((p) => p.hidden == false);
    },
  },
  watch: {
    openMenuSlide() {
      document.documentElement.style.overflow = "hidden";
      var x = document.getElementById("menu-mobile");
      var y = document.getElementById("clear-fix");
      if (x.style.display === "none" || x.style.display === "") {
        x.style.display = "block";
        y.style.display = "block";
        document.getElementById("fullMenu").style.display = "flex";
      } else {
        x.style.display = "none";
        y.style.display = "none";
        document.getElementById("fullMenu").style.display = "none";
        document.querySelector("body").style.overflow = "hidden";
      }
    },
  },
  methods: {
    btPush(data) {
      this.$router.push(data.path);
      this.closeNavMenu();
    },
    closeNavMenu() {
      var x = document.getElementById("menu-mobile");
      var y = document.getElementById("clear-fix");
      x.style.display = "none";
      y.style.display = "none";
      document.getElementById("fullMenu").style.display = "none";
      document.documentElement.style.overflow = "auto";
      document.querySelector("body").style.removeProperty("overflow");
    },
  },
};
</script>
<style scoped>
.menu-container {
  background: #fff;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}
.menu-container-top {
  width: 1240px;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  position: relative;
  /* z-index: 10; */
}
.menu-frame-menu {
  padding: 15px 0px;
  cursor: pointer;
}
.menu-frame-menu:hover {
  color: #c3262e;
  font-weight: 700 !important;
}
.menu-title {
  display: flex;
  font-size: 16px;
  font-weight: 400;
}
.menu-title:hover {
  color: #c3262e;
  font-weight: 500;
}
.menu-menu {
  position: absolute;
  display: none;
  background-color: rgb(255, 255, 255);
  top: 48px;
  left: -45px;
  width: 277px;
  z-index: 500;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.menu-right-option-items:hover {
  color: #fff;
  background-color: #34a34c;
}
.menu-menu:hover + .menu-frame-menu .menu-title {
  color: #e4803a;
}
.menu-menu-left {
  margin: 0px;
  border-radius: 0 0 0 24px;
  height: var(--heightMega);
  width: 25%;
  background: linear-gradient(90deg, #f0bd4a -10.81%, #e68241 118.47%);
}
.menu-menu-right {
  width: 100%;
}
.menu-menu-right:hover {
  color: #fff;
}
.menu-frame-menu:nth-child(2) .menu-menu {
  position: absolute;
  left: 16%;
}
.menu-frame-menu:nth-child(3) .menu-menu {
  position: absolute;
  left: 31%;
}
.menu-frame-menu:nth-child(4) .menu-menu {
  position: absolute;
  left: 48.5%;
}
.menu-frame-menu:nth-child(5) .menu-menu {
  position: absolute;
  left: 58.5%;
}
.btn-view-all {
  padding: 6px 32px 20px 13px;
  text-align: start;
}
.btn-view-all2 {
  padding: 6px 32px 20px 45px;
  text-align: start;
}

.btn-view-all2 > a {
  font-size: 16px;
  font-weight: 400;
  color: #c3262e;
  text-decoration: none;
}
.btn-view-all2 > i {
  color: #c3262e;
  font-size: 20px;
  margin-left: 4px;
  opacity: 1;
}
.btn-view-all > a {
  margin-left: 30px;
  font-size: 16px;
  font-weight: 400;
  color: #c3262e;
  text-decoration: none;
}
.btn-view-all > i {
  color: #c3262e;
  font-size: 20px;
  margin-left: 4px;
  opacity: 1;
}
.items-menu-right:hover {
  background-color: #c3262e;
  color: #f0bd4a;
}
.menu-menu-right2 {
  margin-top: 16px;
  width: 100%;
  overflow-y: hidden;
  height: 460px;
  /* place-items: center; */
}
.menu-right-option-items {
  height: 36px;
}
.menu-right-option {
  font-size: 16px;
}
.menu-right-option p {
  height: 20px;
  color: #c3262e;

  text-align: start;
  padding: 8px 32px 8px 70px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}
.menu-right-option p:hover {
  color: #fff;
}
.group-title {
  text-align: start !important;
  font-size: 16px;
  font-weight: 400;
  color: #c3262e;
  padding: 10px 32px 7px 45px;
}
.menu-menu:hover .menu-title > span {
  color: red !important ;
}
.group-title:hover {
  color: white;
}
.menu-box-logo {
  margin: auto 8px;
}
.box-logo {
  margin: 8px;
  border-radius: 8px;
  margin-top: 16px;
  background: #fff;
  border: 1px solid #fff;
  height: 100px;
  place-items: center;
  position: relative;
}
.logo {
  width: 70%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.menu-frame-menu:hover .mega-menu1 {
  display: block;
}
.menu-frame-menu:hover .mega-menu2 {
  display: block;
}
.menu-frame-menu:hover .mega-menu3 {
  display: block;
}
.menu-frame-menu:hover .mega-menu4 {
  display: block;
}
.menu-frame-menu:hover .mega-menu5 {
  display: block;
}
.line-holizontal {
  height: 24px;
  width: 5px;
  margin-right: 8px;
  border-radius: 2px;
  background: #c3262e;
  display: none;
}
@media (min-width: 0) and (max-width: 1200px) {
  .menu-container {
    display: none !important;
  }
}

.mobile-nav {
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  padding-top: 18px;
  bottom: 0;
  z-index: 500;
  background: #fff;
  /* background: rgba(0, 0, 0, 0.8); */
  /* left: -260px; */
  min-width: 240px;
  max-width: 300px;
  overflow-y: auto;
  transition: 0.4s;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
}
.navbar-item-mobile {
  min-height: 30px;
  margin: 0px;
  line-height: 15px;
  width: 100%;
}
.navbar-title-mobile {
  color: #c3262e;
  margin-top: 7px;
  margin-left: 5px;
  font-weight: 600;
}

.navbar-title-mobile:hover {
  cursor: pointer;
  background: none;
}
.menu-item-mobile {
  margin-top: 5px;
  width: 100%;
  list-style: none;
  padding-left: 0px;
}
#menuTPCNMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0;
  overflow: hidden;
}
#menuDMPMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0;
  overflow: hidden;
}
#menuTMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0px;
  overflow: hidden;
  position: relative;
}
#menuCSCNMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0px;
  overflow: hidden;
  position: relative;
}
#menuCDMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0px;
  overflow: hidden;
  position: relative;
}
#menuTBYTMobile {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0px;
  overflow: hidden;
  position: relative;
}

.li-mobile {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 7px;
  cursor: pointer;
}
.item-mobile {
  /* margin: 6px;
  margin-left: 5px; */
  color: #c3262e;
}
.clear-fix-layout {
  display: none;
  position: fixed;
  right: 0;
  top: 0;
  /* width: 600px; */
  z-index: 8;
  width: calc(100% - 240px);
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.fa-chevron-down {
  cursor: pointer;
}
</style>

<style>
.el-loading-mask {
  z-index: 1000 !important;
}
</style>
