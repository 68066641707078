import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import home from "../controller/home.vue";
import productLst from "../views/body/productLst.vue";
import productInfo from "../views/body/productInfo.vue";
import userCart from "../views/body/userCart.vue";
import shopLst from "../views/body/shopLst.vue";
import recruit from "../views/recruit/recruit.vue";
import detailUserCare from "../views/body/usercare/usercareDetail.vue";
import chinh_sach_chung from "../views/body/policy/general_policy.vue";
import genuine from "../views/body/policy/genuine.vue";
import information_security from "../views/body/policy/information_security.vue";
import return_goods from "../views/body/policy/return_goods.vue";
import delivery from "../views/body/policy/delivery.vue";
import intro_company from "../views/body/company/intro_company.vue";
import usercareDetail from "../views/body/usercare/usercareDetail.vue";
import programLoyalPoint from "../views/body/programLoyalPoint.vue";

export const constantRoutes = [
  {
    path: "/",
    component: home,
    meta: {
      hideChatBox: false,
    },
    name: "Trang chủ",
    hidden: false,
  },
  {
    path: "/danh-sach-san-pham",
    component: productLst,
    meta: {
      hideChatBox: false,
    },
    name: "Danh sách sản phẩm",
    hidden: false,
  },
  {
    path: "/mua-ho-hang-Nhat",
    component: () => import("@/views/body/supportBuy/index"),
    meta: {
      hideChatBox: false,
    },
    name: "Mua hộ hàng Nhật",
    hidden: false,
  },
  {
    path: "/thong-tin-san-pham/:id",
    component: productInfo,
    meta: {
      hideChatBox: false,
    },
    name: "Thông tin sản phẩm",
    hidden: true,
  },
  {
    path: "/gio-hang",
    component: userCart,
    meta: {
      hideChatBox: true,
    },
    name: "Giỏ hàng",
    hidden: true,
  },
  {
    path: "/dia-chi",
    component: shopLst,
    meta: {
      hideChatBox: false,
    },
    name: "Địa chỉ",
    hidden: true,
  },
  {
    path: "/tin-tuc-bai-viet",
    component: recruit,
    meta: {
      hideChatBox: false,
    },
    name: "Tin tức - Bài viết",
    hidden: false,
  },
  {
    path: "/tin-tuc-bai-viet/:name/:id",
    component: detailUserCare,
    meta: {
      hideChatBox: false,
    },
    name: "Tin tức - Bài viết",
    hidden: true,
  },
  {
    path: "/chinh-sach",
    component: chinh_sach_chung,
    meta: {
      hideChatBox: false,
    },
    name: "Chính sách",
    hidden: false,
  },
  {
    path: "/chinh-sach-hang-chinh-hang",
    component: genuine,
    meta: {
      hideChatBox: false,
    },
    name: "Chính sách hàng chính sãng",
    hidden: true,
  },
  {
    path: "/chinh-sach-bao-mat-thong-tin",
    component: information_security,
    meta: {
      hideChatBox: false,
    },
    name: "Chính sách bảo mật thông tin",
    hidden: true,
  },
  {
    path: "/chinh-sach-doi-tra",
    component: return_goods,
    meta: {
      hideChatBox: false,
    },
    name: "Chính sách đổi trả",
    hidden: true,
  },
  {
    path: "/chinh-sach-giao-hang",
    component: delivery,
    meta: {
      hideChatBox: false,
    },
    name: "Chính sách giao hàng",
    hidden: true,
  },
  {
    path: "/gioi-thieu-cong-ty",
    component: intro_company,
    meta: {
      hideChatBox: false,
    },
    name: "Giới thiệu công ty",
    hidden: true,
  },

  {
    path: "/bai-viet/:name/:id",
    component: usercareDetail,
    meta: {
      hideChatBox: false,
    },
    name: "Bài viết",
    hidden: true,
  },

  {
    path: "/huong-dan",
    component: programLoyalPoint,
    meta: {
      hideChatBox: true,
    },
    name: "Hướng dẫn",
    hidden: false,
  },
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
    mode: "history",
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
