<template>
  <div class="form container">
    <div class="tagname mt-20">
      <a class="tag" href="">Trang chủ / </a>
      <a class="tag" href="">Hướng dẫn</a>
    </div>
    <div class="recruit-frame">
      <h1>Tin tức - bài viết</h1>

      <div class="lst-item">
        <div
          class="new-item"
          v-for="(item, index) in lstTypeNew"
          :key="index"
          @click="typeSelect = item.Value"
          :class="typeSelect == item.Value ? 'color-select' : ''"
        >
          {{ item.Text }}
        </div>
      </div>
      <div class="userCare-cellLst">
        <div class="lstCareCell">
          <userCareCell
            v-for="(userCare, index) in userCareLst"
            :key="index"
            :userCareInfo="userCare"
          ></userCareCell>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetDefaultValuePublic } from "@/api/defaultApi.js";
import { Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import { TGetPostLstPublic } from "@/api/postApi.js";
import userCareCell from "../body/components/user-care-cell.vue";
export default {
  components: {
    Swiper,
    SwiperSlide,
    userCareCell,
  },
  data() {
    return {
      lstTypeNew: [],
      userCareLst: [],
      typeSelect: "",
    };
  },
  watch: {
    typeSelect(newValue, oldValue) {
      if (newValue != oldValue) {
        this.getPostLstPublic();
      }
    },
  },
  methods: {
    getDefaultValuePublic() {
      GetDefaultValuePublic({ Table: "TypeNew" }).then((res) => {
        this.lstTypeNew = [{ Value: "", Text: "Tất cả" }].concat(
          res.Data.map((item) => {
            return { ...item, Text: item.Value };
          })
        );

        console.log(res.Data);
      });
    },
    getPostLstPublic() {
      TGetPostLstPublic({
        PageNumber: 1,
        RowspPage: 40,
        Search: "",
        Type: this.typeSelect,
      }).then((res) => {
        if (res.RespCode == 0) {
          this.userCareLst = res.Data;
        }
      });
    },
  },
  created() {
    this.getDefaultValuePublic();
    this.getPostLstPublic();
  },
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #c3262e;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.tagname {
  margin-left: 8px;
}
.container {
  display: block;
}
.userCare-cellLst {
  min-height: 500px;
  background: #e4edff;
  margin: -10px;
  margin-top: 16px;

  .lstCareCell {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }
}
.recruit-frame {
  border-radius: 8px;
  background-color: #e4edff;
  padding: 20px;
  margin-top: 20px;
  // display: flex;
  h1 {
    // text-align: center;
    color: #c3262e;
    font-weight: normal;
    margin-bottom: 16px;
  }
  .lst-item {
    display: flex;
    .new-item {
      background: #fff;
      cursor: pointer;
      border: 1px solid #4a4f63;
      color: #4a4f63;
      height: 36px;
      border-radius: 18px;
      font-size: 14px;
      padding: 0 16px;
      line-height: 36px;
      font-weight: 500;
      width: auto;
      margin-right: 8px;
    }
  }
}
.color-select {
  background: #1250dc !important;
  color: #fff !important;
  border: 1px solid #1250dc !important;
}
@media screen and (max-width: 678px) {
  .recruit-frame {
    h1 {
      font-size: 20px;
      color: #000;
      font-weight: 500;
    }
    .lst-item {
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
      }
      .new-item {
        white-space: nowrap;
        line-height: 32px;
        padding: 0 12px;
        height: 32px;
      }
    }
    .userCare-cellLst {
      .lstCareCell {
        grid-template-columns: 100%;
      }
    }
  }
}
</style>
