<template>
  <div class="container-userCare">
    <div class="userCare-mobile-frame">
      <div class="userCare-mobile-header background-gradient">
        <div class="userCare-title">
          <i class="fa-solid fa-signs-post"></i>
          <h4 class="pl-10">Tin tức - Bài viết</h4>
        </div>
        <!-- <button class="userCare-bt-seeall">Xem tất cả >></button> -->
      </div>
      <div class="userCare-mobile-frame-cellLst" v-loading="loading">
        <div class="lstCareCell">
          <userCareCell
            v-for="(userCare, index) in userCareLst"
            :key="index"
            :userCareInfo="userCare"
          ></userCareCell>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userCareCell from "../components/user-care-cell.vue";
import { TGetPostLstPublic } from "@/api/postApi.js";

export default {
  data() {
    return {
      userCareLst: [],
      userCareLstAll: [],
      loading: false,
    };
  },
  components: {
    userCareCell,
  },
  methods: {
    getPostLstPublic() {
      TGetPostLstPublic({
        PageNumber: 1,
        RowspPage: 8,
        Search: "",
        Type: "",
      }).then((res) => {
        if (res.RespCode == 0) {
          this.userCareLst = res.Data;
        }
      });
    },
  },
  created() {
    this.getPostLstPublic();
  },
};
</script>

<style scoped>
.lstCareCell {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
.userCare-frame {
  /* margin-top: 10px; */
  background-color: #fcf3e8;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.userCare-header {
  background-color: #c3262e;
  height: 44px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.userCare-mobile-frame-cellLst {
  margin-top: 15px;
  margin-bottom: -5px;
  min-height: 200px;
}
.userCare-title {
  display: flex;
  padding-top: 12px;
  padding-left: 20px;
  color: #fff;
}
.userCare-bt-seeall {
  background: none;
  border: none;
  color: #fff;
  margin-right: 20px;
}

.userCare-mobile-header {
  height: 40px;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
}
.userCare-mobile-frame {
  height: auto;
  background: #fcf3e8;
}

@media (max-width: 678px) {
  .lstCareCell {
    /* display: grid; */
    grid-template-columns: 100%;
  }
}
.container-userCare {
  max-width: 1240px !important;
  margin: auto !important;
}
</style>
