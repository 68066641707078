<template>
  <div>
    <div class="container">
      <div class="footer-top background-gradient"></div>
      <div class="footer-body">
        <div class="footer-body-col4">
          <img src="@/assets/images/techsso.png" alt="" />
          <div class="frameicon">
            <div class="frameFace" @click="btOpenLink">
              <i class="fa-brands fa-facebook-f"></i>
            </div>
            <div class="frameFace">
              <i class="fa-solid fa-phone"></i>
            </div>
          </div>
        </div>
        <div class="footer-body-col1">
          <h4>VỀ CHÚNG TÔI</h4>
          <div>
            <ul @click="btIntroCompany">
              Giới thiệu công ty
            </ul>
            <ul @click="btRecruit">
              Địa chỉ
            </ul>
          </div>
          <div class="footer-layout-confirm" style="display: none">
            <img src="@/assets/images/confirm.png" alt="" />
          </div>
        </div>
        <div class="footer-body-col2">
          <h4>DANH MỤC</h4>
          <div>
            <ul @click="btGLevel1Title1">
              Danh sách sản phẩm
            </ul>
            <ul @click="btGLevel1Title2">
              Mua hộ hàng nhật
            </ul>
          </div>
        </div>

        <div class="footer-body-col4">
          <h4>CHÍNH SÁCH - HƯỚNG DẪN</h4>
          <div>
            <ul @click="btGeneralPolicy">
              Hướng dẫn mua hàng
            </ul>
            <ul @click="btReturnGoods">
              Chính sách đổi trả
            </ul>
            <ul @click="btDelivery">
              Hướng dẫn mua hộ
            </ul>
          </div>
        </div>
        <div class="footer-body-col5">
          <h4>LIÊN HỆ</h4>

          <div style="font-size: 13px; margin-top: 12px">
            <p>Tư vấn mua hàng</p>
            <h3 style="color: #c3262e">0353 155 486</h3>
          </div>
          <div style="font-size: 13px; margin-top: 8px">
            <p>Góp ý, khiếu nại (7h00-22h00)</p>
            <h3 style="color: #c3262e">0353 155 486</h3>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="footer-bottom-line"></div>
        <div style="text-align: center">
          <div class="footer-info-company">
            <p>
              ©2024 CÔNG TY TNHH GIẢI PHÁP CÔNG NGHỆ VÀ DỊCH VỤ TSS Số giấy
              phép: 11/SLDTBXH-GP cấp ngày 12/03/2021 <br />
              Cơ quan cấp phép: Ủy ban nhân dân thành phố Hà Nội, Văn phòng Lao
              động và Thương binh xã hội
            </p>
            <p>
              Địa chỉ: Số 9, ngách 52, ngõ 119, đường Giáp Bát, Phường Giáp Bát,
              Quận Hoàng Mai, Thành phố Hà Nội, Việt Nam
            </p>
            <p>Email: techsso@gmail.vn - Số điện thoại: 0353.155.486</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import footerMobile from "./footerMobile.vue";
export default {
  data() {
    return {
      inputEmail: "",
    };
  },
  components: {
    footerMobile,
  },
  methods: {
    openProgramLoyalPoint() {
      this.$router.push({
        path: "/chuong-trinh-tich-diem",
      });
    },
    openNCC() {
      let routeData = this.$router.resolve({ path: "/dang-ky-nha-phan-phoi" });
      window.open(routeData.href, "_blank");
    },
    btDelivery() {
      this.$router.push({ path: "/huong-dan" });
    },
    btIntroCompany() {
      this.$router.push({ path: "/gioi-thieu-cong-ty" });
    },
    btRecruit() {
      this.$router.push({ path: "/dia-chi" });
    },
    btShopLst() {
      this.$router.push({ path: "/he-thong-cua-hang" });
    },

    btGeneralPolicy() {
      this.$router.push({ path: "/huong-dan" });
    },

    btReturnGoods() {
      this.$router.push({ path: "/chinh-sach" });
    },
    btGLevel1Title1() {
      this.$router.push({
        path: "/danh-sach-san-pham",
      });
    },
    btGLevel1Title2() {
      this.$router.push({
        path: "/mua-ho-hang-Nhat",
      });
    },
    btOpenLink() {
      window.open(
        "https://www.facebook.com/profile.php?id=100068855400399",
        "_blank"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: block;
}
.footer-top {
  margin-top: 16px;
  height: 5px;
  display: flex;
  justify-content: space-between;
}
.footer-top-title {
  color: #fff;
  padding-top: 16px;
  padding-left: 100px;
}
.footer-frame-input {
  padding-right: 100px;
}
.footer-input-email {
  padding-top: 5px;
  width: 400px;
}
ul {
  padding: 2px 0px;
  color: #c3262e;
  font-size: 14px;
  cursor: pointer;
}
a {
  text-decoration: none;
  color: #c3262e;
}
.footer-body {
  padding: 16px;
  padding-top: 30px;
  display: grid;
  grid-template-columns: 21% 18% 18% 23% 20%;
  position: relative;
  padding-bottom: 80px;
}

ul:hover {
  color: #4f79db;
}

.footer-body-col4 {
  margin-bottom: 16px;
  .frameicon {
    display: flex;
    .frameFace {
      width: 20px;
      // height: 32px;
      background: #fff;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      padding: 8px;
      margin-right: 8px;
      i {
        font-size: 16px;
        background: #fff;

        color: #c3262e;
      }
    }
  }
}

.footer-layout-confirm {
  margin-top: 45px;
  margin-left: -20px;
  margin-bottom: 20px;
}

.footer-bottom-line {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.54);
}
.footer-info-company {
  margin-top: 15px;
  font-size: 14px;
  margin-bottom: 30px;
  color: rgba(0, 0, 0, 0.7);
  p {
    line-height: 24px;
  }
}
.flex-space {
  display: flex;
  justify-content: space-between;
}

.frame-footer-mobile {
  height: 75px;
}
@media screen and (max-width: 678px) {
  .footer-body {
    grid-template-columns: 50% 50%;
  }
}
</style>
