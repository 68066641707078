<template>
  <div class="container" style="line-height: 25px">
    <div class="genui-container">
      <h1>CHÍNH SÁCH ĐỔI TRẢ SẢN PHẨM</h1>

      <div>
        <h3 class="mt-10">1. Điều kiện đổi trả</h3>
        <div>
          <h3 class="mt-5">1.1 Các trường hợp hỗ trợ đổi trả</h3>
          <p class="mt-5 ml-30 mr-30">
            Techsso hỗ trợ đổi trả sản phẩm được mua sắm trực tuyến qua: Trang
            web Techsso.vn hoặc tổng đài đặt hàng miễn cước 0866.681.899 như
            sau: <br />
            - Sản phẩm gây ra tác dụng không mong muốn cho khách hàng khi sử
            dụng <br />
            &nbsp; &nbsp; • Các tác dụng không mong muốn gặp phải được chấp nhận
            đổi trả gồm hoa mắt, chóng mặt, tiêu chảy, đau bụng, run chân tay,
            tim đập nhanh, thay đổi huyết áp… <br />
            - Sản phẩm bị lỗi được xác định là do lỗi từ nhà cung cấp: Các lỗi
            được chấp nhận đổi trả gồm móp méo, biến dạng, biến màu, chảy nước
            hoặc bong tróc… <br />
            - Sản phẩm giao đến không đúng hoặc không đủ với đơn hàng đã đặt và
            thỏa 2 điều kiện: <br />
            &nbsp; &nbsp; • Sản phẩm hoàn trả chưa qua sử dụng và còn nguyên vẹn
            bao bì ban đầu như tem, nhãn dán, hộp, mã vạch, thẻ bảo hành (nếu
            có), phiếu giảm giá hoặc quà tặng kèm (nếu có). <br />
            &nbsp; &nbsp; • Sản phẩm được hoàn trả cùng với biên nhận, hóa đơn
            hoặc biên lai thuế VAT (nếu có) Với các trường hợp trên, Techsso hỗ
            trợ khách hàng đổi sản phẩm đúng và nguyên vẹn, bổ sung sản phẩm còn
            thiếu hoặc trả sản phẩm và được hoàn tiền theo mục 3. Chính sách
            hoàn tiền
          </p>
        </div>
        <div>
          <h3 class="mt-5">1.2 Các trường hợp không hỗ trợ đổi trả</h3>
          <p class="mt-5 ml-30 mr-30">
            Chính sách đổi trả không áp dụng cho trường hợp: <br />
            - Các yêu cầu trả hàng hoặc hoàn tiền thuộc về cảm quan/không ưng
            ý/thay đổi quyết định mua hàng. <br />
            - Các đơn hàng có sử dụng Phiếu quà tặng (gift voucher). <br />
            - Các sản phẩm chỉ khác nhau về bao bì, mẫu mã bên ngoài theo lô
            hàng của nhà sản xuất mà không có sự thay đổi về thành phần, chất
            lượng. <br />
            - Các sản phẩm là mặt hàng trữ lạnh, hàng tiêm chích. <br />
            - Các sản phẩm nằm trong các chương trình không được phép đổi trả.
            <br />
            - Đơn hàng quá 3 ngày kể từ ngày nhận được đơn hàng. <br />
            Để tránh các trường hợp đáng tiếc có thể xảy ra, khách hàng vui lòng
            kiểm tra kĩ đơn hàng với nhân viên giao nhận hoặc dược sĩ  
            tại thời điểm nhận hàng và có quyền từ chối nhận hàng nếu các sản
            phẩm không đúng như mong đợi của quý khách. Việc đồng kiểm sản phẩm
            sẽ hỗ trợ Techsso xử lý nhanh chóng hơn.
          </p>
        </div>
      </div>
      <div>
        <h3 class="mt-10">2. Quy trình đổi trả</h3>
        <div>
          <!-- <img
            src="@/assets/Ảnh chụp Màn hình 2022-10-05 lúc 14.19.03.png"
            alt=""
          /> -->
        </div>
      </div>
      <div>
        <h3 class="mt-10">3. Chính sách hoàn tiền</h3>

        <div>
          <h3 class="mt-5">3.1. Giá trị hoàn trả</h3>
          <p class="mt-5 ml-30 mr-30">
            Giá trị hoàn trả được tính bằng giá trị sản phẩm hoặc đơn hàng tính
            tại thời điểm đơn hàng được xác nhận qua tin nhắn điện thoại (SMS).
          </p>
        </div>

        <div>
          <h3 class="mt-5">3.2. Hình thức hoàn tiền</h3>
          <p class="mt-5 ml-30 mr-30">
            Quý khách hàng có 2 phương thức nhận tiền hoàn trả như sau: <br />
          </p>
          <p class="font-size-h4">
            3.2.1. Khách hàng mang sản phẩm đã mua (bao gồm vỏ hộp, giấy hướng
            dẫn sử dụng kèm theo) tới cửa hàng   Techsso gần nhất để
            được thực hiện đổi trả và hoàn tiền.
          </p>
          Hoàn tiền tại quầy: Cửa hàng chi tiền mặt tại quầy cho khách hàng.

          <p class="font-size-h4">
            3.2.2. Quý khách nhận lại tiền chuyển khoản nếu như đã thanh toán
            trước với nhân viên tư vấn của Techsso <br />
            Thời gian nhận tiền chuyển khoản ngân hàng: 7-15 ngày làm việc tùy
            theo ngân hàng khách hàng sử dụng.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h1 {
  margin-top: 10px;
  text-align: center;
  color: #e35a14;
}
p {
  font-size: 16px;
  line-height: 1.4;
}
.genui-container {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  padding: 40px;
  margin-top: 20px;
}
table {
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  padding: 5px;
  border: 1px solid rgba(0, 0, 0, 0.24);
}
</style>
