<template>
  <div class="container-cart" style="min-height: 500px" v-loading="showLoading">
    <div class="tagname mt-20">
      <a class="tag" href="">Trang chủ / </a>
      <a class="tag" href="">Giỏ hàng của bạn</a>
    </div>

    <div class="flex-between-cart mt-20">
      <div class="cart-container-info">
        <div class="cart-col-info">
          <h3 class="font-size-h5">Giỏ hàng</h3>
          <div class="cart-info-title">
            <p style="width: 40%" class="font-size-h5">
              Tất cả ({{ productInCart.length }} sp)
            </p>
            <p class="cart-display" style="width: 10%; text-align: center">
              Đơn vị
            </p>
            <p class="cart-display" style="width: 10%; text-align: center">
              Đơn giá
            </p>
            <p
              class="cart-display"
              style="
                width: 10%;
                text-align: center;
                margin-left: 0px !important;
              "
            >
              Số lượng
            </p>
            <p
              class="cart-display"
              style="
                width: 10%;
                text-align: center;
                margin-left: 0px !important;
              "
            >
              Thành tiền
            </p>
            <button v-if="productInCart.length > 0" @click="deleteAll">
              <i class="fa-solid fa-trash-can"></i>
            </button>
          </div>
        </div>
        <!--  -->
        <div
          ref="tableCart"
          class="cart-container-item"
          v-for="(product, index) in this.productInCart"
          :key="index"
        >
          <cartCell
            :productBuy="product"
            @updateProduct="updateProduct"
            @deleteProduct="deleteProduct"
          ></cartCell>
        </div>

        <div v-if="productInCart.length == 0">
          <p style="text-align: center; margin-top: 20px">
            Bạn chưa có sản phẩm nào trong giỏ
          </p>
        </div>

        <!-- THÔNG TIN ĐỊA CHỈ GIAO HÀNG -->
        <el-row v-if="productInCart.length > 0">
          <el-col :lg="16" :md="16">
            <div id="addressLayout" class="cart-container-address">
              <h3 class="font-size-h5">Địa chỉ giao hàng</h3>
              <div class="cart-address-title">
                <p class="mt-10 ml-30 font-size-p">Họ tên</p>
                <el-input
                  class="cart-input"
                  placeholder="Nhập họ và tên (bắt buộc)"
                  type="text"
                  v-model="orderInfo.CustomerName"
                ></el-input>
              </div>
              <div class="cart-address-title">
                <p class="mt-10 ml-30 font-size-p">Số điện thoại</p>
                <el-input
                  class="cart-input"
                  placeholder="Nhập số điện thoại (bắt buộc)"
                  v-model="orderInfo.CustomerPhone"
                ></el-input>
              </div>
              <div class="cart-address-title">
                <p class="mt-10 ml-30 font-size-p">Email</p>
                <el-input
                  class="cart-input"
                  type="email"
                  placeholder="Nhập email (nếu có)"
                  v-model="orderInfo.CustomerEmail"
                ></el-input>
              </div>
              <div class="cart-address-title">
                <p class="mt-10 ml-30 font-size-p">Tỉnh/Tp</p>

                <el-select
                  class="cart-select"
                  v-model="orderInfo.City"
                  filterable
                  clearable=""
                  no-data-text="Chưa có thông tin"
                  @change="getDistrictLst"
                  placeholder="Nhập tỉnh/tp (bắt buộc)"
                >
                  <el-option
                    v-for="item in cityLst"
                    :key="item.City"
                    :label="item.City"
                    :value="item.City"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="cart-address-title">
                <p class="mt-10 ml-30 font-size-p">Quận/Huyện</p>
                <el-select
                  class="cart-select"
                  v-model="orderInfo.District"
                  filterable
                  no-data-text="Chưa có thông tin"
                  @change="getCommuneLst"
                  placeholder="Nhập quận/huyện (bắt buộc)"
                >
                  <el-option
                    v-for="item in districtLst"
                    :key="item.District"
                    :label="item.District"
                    :value="item.District"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="cart-address-title">
                <p class="mt-10 ml-30 font-size-p">Phường/Xã</p>
                <el-select
                  class="cart-select"
                  v-model="orderInfo.Commune"
                  filterable
                  no-data-text="Chưa có thông tin"
                  placeholder="Nhập phường/xã (bắt buộc)"
                >
                  <el-option
                    v-for="item in communeLst"
                    :key="item.Commune"
                    :label="item.Commune"
                    :value="item.Commune"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="cart-address-title">
                <p class="mt-10 ml-30 font-size-p">Địa chỉ</p>
                <el-input
                  class="cart-input"
                  placeholder="Nhập số nhà/ tên đường (bắt buộc)"
                  v-model="orderInfo.Address"
                ></el-input>
              </div>
            </div>
          </el-col>
          <el-col :lg="8">
            <div class="cart-container-bill">
              <h5 class="font-size-h5">Hóa đơn</h5>
              <div class="cart-address-title">
                <p class="mt-10 ml-30 font-size-p">Tổng tiền</p>
                <p class="mt-10 ml-30 font-size-p">
                  {{ new Intl.NumberFormat().format(orderInfo.TotalMoney) }}đ
                </p>
              </div>
              <div class="cart-address-title">
                <p class="mt-10 ml-30 font-size-p">Khuyến mại</p>
                <p class="mt-10 ml-30 font-size-p">0đ</p>
              </div>
              <div class="cart-address-title">
                <p class="mt-10 ml-30 font-size-p">Hình thức thanh toán</p>
                <el-select
                  v-model="orderInfo.Payment"
                  placeholder="HTTT"
                  size="medium"
                  style="width: 150px"
                >
                  <el-option
                    v-for="item in typePaymentLst"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="line"></div>
              <div class="cart-address-title">
                <h5 class="font-size-h4" style="color: #c3262e">Thành tiền</h5>
                <h4 class="ml-30 font-size-h4">
                  {{ new Intl.NumberFormat().format(orderInfo.TotalMoney) }}đ
                </h4>
              </div>
              <div
                v-if="displayCaptcha"
                style="margin-top: 20px; display: flex; justify-content: center"
              >
                <span>
                  <vue-recaptcha
                    sitekey="6Lee90MpAAAAABdb9qjJZCMHETHCjpfcDOU0Q0CQ"
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="onExpired"
                    :loadRecaptchaScript="true"
                  ></vue-recaptcha>
                </span>
              </div>
              <button class="confirm-bill button-gradient" @click="createOrder">
                Hoàn tất đặt hàng
              </button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div id="dialogLayoutRespond" v-if="isShowSuccess">
      <div id="clearDialogRespond" @click="closeDialogSuccess"></div>

      <div id="dialogSuccess">
        <i
          class="fa-solid fa-circle-check"
          style="font-size: 40px; color: green"
        ></i>
        <p
          class="font-size-h3"
          style="font-weight: 600; text-align: center; margin-top: 10px"
        >
          Đặt hàng thành công!
        </p>
        <p class="font-size-p" style="text-align: center; margin-top: 10px">
          Cảm ơn bạn đã đặt hàng, chúng tôi sẽ liên hệ lại với quý khách để xác
          nhận đơn hàng.
        </p>
      </div>
    </div>
    <div id="dialogLayoutRespond" v-if="isShowFail">
      <div id="clearDialogRespond" @click="closeDialogFail"></div>

      <div id="dialogFail">
        <i
          class="fa-solid fa-circle-exclamation"
          style="font-size: 40px; color: #f9ba0f"
        ></i>
        <p
          class="font-size-h3"
          style="font-weight: 600; text-align: center; margin-top: 10px"
        >
          Kiểm tra lại đơn hàng!
        </p>
        <p
          id="textError"
          class="font-size-p"
          style="text-align: center; margin-top: 10px"
        >
          Quý khách vui lòng kiểm tra lại thông tin đơn hàng
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import cartCell from "../body/components/cartCell.vue";

import {
  GetCity,
  GetCommuneByCityAndDistrict,
  GetDistrictByCity,
  CreateOrder,
} from "@/api/productApi.js";
import { setProductCartLst, getProductCartLst } from "@/store/index.js";
import { CreateCusOrderHeader } from "@/api/cusOrderApi.js";
import VueRecaptcha from "vue-recaptcha";

export default {
  data() {
    return {
      idBrowser: "",
      input: "",
      input3: "",
      productInCart: [],
      cityLst: [],
      showLoading: false,
      districtLst: [],
      communeLst: [],
      orderInfo: {
        Payment: "Tiền mặt",
        TotalMoney: 0,
      },

      typePaymentLst: ["Tiền mặt", "Chuyển khoản"],
      isShowSuccess: false,
      isShowFail: false,
    };
  },
  components: {
    cartCell,
    VueRecaptcha,
  },

  watch: {
    productInCart(newValue) {
      var total = newValue.reduce((a, b) => {
        return a + b.ExpriceShow * b.QuantityProduct;
      }, 0);
      this.orderInfo.TotalMoney = total;
    },
  },
  computed: {
    displayCaptcha() {
      if (
        this.orderInfo.CustomerName &&
        this.orderInfo.CustomerPhone &&
        this.orderInfo.City &&
        this.orderInfo.District &&
        this.orderInfo.Commune &&
        this.orderInfo.Address
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    updateProduct() {
      var total = this.productInCart.reduce((a, b) => {
        return a + b.ExpriceShow * b.QuantityProduct;
      }, 0);
      this.orderInfo.TotalMoney = total;
      setProductCartLst(this.productInCart);
    },

    deleteAll() {
      this.$confirm(
        "Có chắc bạn muốn xóa tất cả sản phẩm không?",
        "Xóa giỏ hàng",
        {
          confirmButtonText: "Xóa",
          cancelButtonText: "Hủy",
        }
      ).then(() => {
        setProductCartLst([]);
        this.productInCart = [];
      });
    },
    openDialogFail() {
      this.isShowFail = true;
    },
    openDialogSucess() {
      this.isShowSuccess = true;
    },
    closeDialogFail() {
      this.isShowFail = false;
    },
    closeDialogSuccess() {
      this.isShowSuccess = false;
      this.orderInfo = {};
      setProductCartLst(null);
      this.getProductInCart();
    },
    createOrder() {
      if (
        this.orderInfo.CustomerName &&
        this.orderInfo.CustomerPhone &&
        this.orderInfo.City &&
        this.orderInfo.District &&
        this.orderInfo.Commune &&
        this.orderInfo.Address
      ) {
        if (this.checkPhoneNumber(this.orderInfo.CustomerPhone)) {
          CreateCusOrderHeader({
            Data: {
              ...this.orderInfo,
              Quantity: this.productInCart.length,
              Data: this.productInCart.map((item) => {
                return {
                  ...item,
                  Quantity: item.QuantityProduct,
                };
              }),
            },
          }).then((res) => {
            if (res.RespCode === 0) {
              this.openDialogSucess();
            } else {
              this.openDialogFail();
            }
          });
        } else {
          this.$notify({
            title: "Nhắc nhở",
            message: "Vui lòng kiểm tra lại số điện thoại",
            type: "warning",
          });
        }
      } else {
        this.$notify({
          title: "Nhắc nhở",
          message: "Vui lòng nhập đầy đủ thông tin",
          type: "warning",
        });
      }
    },
    checkPhoneNumber(phoneNumber) {
      // Sử dụng biểu thức chính quy để kiểm tra định dạng số điện thoại
      const phoneRegex = /^[0-9]{10}$/;

      // Kiểm tra xem chuỗi phoneNumber phù hợp với biểu thức chính quy không
      return phoneRegex.test(phoneNumber);
    },
    getCityLst() {
      GetCity({}).then((res) => {
        this.cityLst = res.Data;
      });
    },
    getDistrictLst() {
      GetDistrictByCity({ City: this.orderInfo.City }).then((res) => {
        this.districtLst = res.Data;
      });
    },
    getCommuneLst() {
      GetCommuneByCityAndDistrict({
        City: this.orderInfo.City,
        District: this.orderInfo.District,
      }).then((res) => {
        this.communeLst = res.Data;
      });
    },

    getProductInCart() {
      var productCartLst = getProductCartLst();
      if (productCartLst) {
        this.productInCart = productCartLst;
      } else {
        this.productInCart = [];
      }
    },
    deleteProduct(data) {
      this.productInCart = this.productInCart.filter((p) => p.Key != data.Key);
      setProductCartLst(this.productInCart);
    },
  },
  created() {
    this.getProductInCart();
    this.getCityLst();
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #c3262e;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.cart-container-info {
  width: calc(100%);
  margin: auto;
}
.cart-col-info {
  width: calc(100% - 40px);
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
}
.cart-info-title {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: -10px;
  color: rgba(0, 0, 0, 0.54);
}
.cart-info-title button {
  border: none;
  color: rgba(0, 0, 0, 0.54);
  background-color: #fff;
}
.cart-container-address {
  background-color: #fff;
  margin-top: 20px;
  padding: 20px;
  width: calc(100% - 40px);
  border-radius: 8px;
}
.cart-container-bill {
  background-color: #fff;
  margin-top: 20px;
  margin-left: 20px;
  padding: 20px;
  width: calc(100% - 60px);
  border-radius: 8px;
}
.cart-address-title {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.cart-input {
  width: 75%;
}
.cart-select {
  width: 75%;
}

.flex-between-cart {
  display: flex;
  justify-content: space-between;
}
.line {
  height: 1px;
  width: 100%;
  background: rgb(0, 0, 0, 0.5);
  margin: 16px 0;
}
/* Thông tin đơn hàng */
.cart-container-price {
  width: calc(30% - 30px);
  background-color: #fff;
  border-radius: 8px;
  height: 250px;
  /* display: wrap; */
}
.cart-frame-input {
  margin: 10px 20px;
}
.input-with-select .el-button {
  background-color: #c3262e;
  border-radius: 0px 2px 2px 0px;
  color: #fff;
}
.cart-container-item {
  background-color: #fff;
  margin-top: 10px;
  padding: 10px 20px 15px 20px;

  width: calc(100% -40px);
  border-radius: 8px;
}
/* Đơn giá */

.cart-line-vertical {
  height: 1px;
  width: 100%;
  margin: 10px auto;
  background-color: rgba(0, 0, 0, 0.25);
}
.cart-bt-order {
  border: none;
  color: #fff;
  height: 44px;
  width: 100%;
  margin: 15px auto;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .flex-between-cart {
    display: block !important;
  }
  .cart-container-info {
    width: calc(100% - 80px) !important;
    /* margin: 0px 20px !important; */
    margin: auto !important;
  }
}

@media (min-width: 1200px) {
  .container-cart {
    width: 1240px !important;
    margin: auto !important;
  }
}
@media screen and (max-width: 678px) {
  .tagname {
    margin-left: 8px;
  }
  .cart-container-info {
    width: calc(100% - 16px) !important;
  }
  .cart-container-bill {
    margin-left: 0px;
    width: calc(100% - 40px) !important;
  }
}
#dialogLayoutRespond {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  background: rgba(2, 2, 2, 0.4);
  transition: opacity 0.2s ease-in;
  z-index: 1000;
}
#dialogSuccess {
  width: fit-content;
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 40px;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#dialogFail {
  width: fit-content;
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 40px;
  z-index: 10;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#clearDialogRespond {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
@media (max-width: 998px) {
  .cart-display {
    display: none !important;
  }
}
.font-size-p {
  margin-left: 0px !important;
}
.confirm-bill {
  width: 100%;
  border-radius: 50px;
  height: 40px;
  font-size: 16px;
  color: #fff;
  border: none;
  margin-bottom: 0;
}
</style>

<style>
.inline-input {
  width: 75% !important;
}
.cart-quantity-number .el-input__inner {
  border: none;
  background-color: #f1f1f1;
  width: 100%;
  text-align: center;
  padding: 0px;
  color: #000;
  height: 100%;
}
.el-input {
  font-size: 16px !important;
}
</style>
